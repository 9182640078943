import React, { useEffect } from 'react';
import './AshiatsuMassage.css';
// uses the majority of the traditional massage css
import ashiatsu_massage from '../../images/ashiatsu-massage.jpeg';
import ashiatsu_massage_2 from '../../images/ashiatsu-massage-2.jpeg';
import useWindowDimensions from '../../components/getWindowDimensions';
import YoutubeVideo from '../../components/YoutubeVideo';

function AshiatsuMassage() {

    const width = useWindowDimensions().width;

    function FirstHalf() {
        useEffect(() => {
            const img2 = document.getElementById('img2');
            const invisibleDiv = document.getElementById('invisibleDiv');
          
            if (invisibleDiv !== null) {
              invisibleDiv.style.height = img2.height + "px";
              invisibleDiv.style.width = img2.width + "px";
            }
        });
    
        if (width < 900) {
            return (
                <React.Fragment>
                    <div className='container flex-column'>
    
                        <div>
                            <span className='recoleta medium text-center center'>Ashiatsu Massage</span>
                            <br/>
                            <span className='text-center center line-height'>
                                I will use my feet and up to my full body weight to provide a Deep, Slow and Compressive Pressure
                                that penetrates the deeper layers of the tissues. Because it significantly stretches shortened muscles 
                                of the spine and creates spaces in between the vertebrae it elongates the spine, therefore improving 
                                posture and decreasing pain associated with overly tight muscles. (Watch my video below)
                            </span>
                        </div>
    
                        <div className='flex-column center fade-outer justify-content-center'>
                            <div id='invisibleDiv' className='img-resize' style={{minHeight: '420px'}}/>
                            <img src={ashiatsu_massage} className='fade-inner img-resize rounded-borders' alt='img1'/>
                            <img id='img2' src={ashiatsu_massage_2} className='fade-inner top img-resize rounded-borders' alt='img2'/>
                        </div>
    
                    </div>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <div className='container flex-row'>
    
                        <div className='flex-column center fade-outer justify-content-center' style={{minHeight: '450px'}}>
                            <div id='invisibleDiv'/>
                            <img src={ashiatsu_massage} className='fade-inner rounded-borders img-size' alt='img1'/>
                            <img id='img2' src={ashiatsu_massage_2} className='fade-inner top rounded-borders img-size' alt='img2'/>
                        </div>
    
                        <div className='flex-column justify-content-center'>
                            <span className='recoleta medium text-center center'>Ashiatsu Massage</span>
                            <br/>
                            <span className='text-center center line-height'>
                                I will use my feet and up to my full body weight to provide a Deep, Slow and Compressive Pressure
                                that penetrates the deeper layers of the tissues. Because it significantly stretches shortened muscles 
                                of the spine and creates spaces in between the vertebrae it elongates the spine, therefore improving 
                                posture and decreasing pain associated with overly tight muscles. (Watch my video below)
                            </span>
                        </div>
    
                    </div>
                </React.Fragment>
            );
        }
    
    }

    return(
        <React.Fragment>
            <FirstHalf/>
            <div className='video-container'>
                <span className='recoleta medium text-center center'>What is Ashiatsu?</span>
                <span className='text-center center line-height width-text'>
                    See me explaining and demonstrating Ashiatsu when I was part of the Neroli Spa team:
                </span>
                <YoutubeVideo/>
            </div>
        </React.Fragment>
    );

}

export default AshiatsuMassage
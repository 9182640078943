import React from 'react';


// has "None of the above" feature where it'll uncheck everything else if that's one of the options
function MultiOptionCheckBox({ options, selectedOptions=[], setSelectedOptions }) {

  const handleOptionChange = (event, index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = event.target.checked;
    if (options[options.length - 1] === "None of the above") {
        if(index === options.length - 1) {
            for (let i = 0; i < options.length - 1; i++) {
                newSelectedOptions[i] = false;
            }
        }
        else {
            newSelectedOptions[options.length - 1] = false;
        }
    }
    setSelectedOptions(newSelectedOptions);
  }

  return (
    <div className='flex-column' style={{ gap: '10px' }}>
      {options.map((option, index) => (
        <div key={index}>
          <input
            type="checkbox"
            id={`${options[index]}`}
            name={`${options[index]}`}
            checked={selectedOptions[index]}
            onChange={(event) => handleOptionChange(event, index)}
            style={{cursor: 'pointer'}}
          />
          <label style={{cursor: 'pointer', userSelect: 'none'}} htmlFor={`${options[index]}`}>{option}</label>
        </div>
      ))}
    </div>
  )
}

export default MultiOptionCheckBox;


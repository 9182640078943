import React, {useRef, useEffect} from 'react';
import './StudioTab.css';
import home1 from '../../images/home1.webp';
import home2 from '../../images/home2.webp';
import home3 from '../../images/home3.webp';
import home4 from '../../images/home4.webp';
import useWindowDimensions from '../../components/getWindowDimensions';

function StudioOutside() {

    const width = useWindowDimensions().width;

    const instructionsRef = useRef(null);

    useEffect(() => {
        if (instructionsRef.current) {
          const { offsetLeft } = instructionsRef.current.firstChild;

          const textContainer = document.getElementsByClassName('text-container')[0];
          
          textContainer.style.marginLeft = offsetLeft + 'px';
        }
      }, [instructionsRef]);

    if (width < 725) {
        return (
            <React.Fragment>
    
                <div className='studio-mandala-container flex-column'>
    
                    <div className='flex-column text-container'>
                        <span className='recoleta medium text-center center subtitle-padding'> Welcome to my Home Studio</span>
                        <span className='text-center center subtitle-padding'>HOME is where my HEART is and the hearts of those I CARE and TRUST.</span>
                    </div>
    
                    <div className='flex-column align-items-center instructions-container instructions-mobile'>
                        <div className='flex-column picture-container'>
    
                            <span className='recoleta instructions-size text-center center'>Locate my home</span>
                            <img src={home1} className='studio-outside-sizing' alt="home1"/>
                            <span className='text-center center'>You'll see the house number on the mailbox.</span>
    
                        </div>
    
                        <div className='flex-column picture-container'>
    
                            <span className='recoleta instructions-size text-center center'>Go head and drive down the driveway</span>
                            <img src={home2} className='studio-outside-sizing' alt="home2"/>
                            <span className='text-center center'>You can park there, where the car in the picture is. There is plenty of parking space
                             for you to "U" turn or backup.</span>
                            
                        </div>
    
                        <div className='flex-column picture-container'>
                            
                            <span className='recoleta instructions-size text-center center'>See the house's deck on your left side</span>
                            <img src={home3} className='studio-outside-sizing' alt="home3"/>
                            <span className='text-center center'>Walk under the deck and through the patio.</span>
    
                        </div>
    
                        <div className='flex-column picture-container'>
    
                            <span className='recoleta instructions-size text-center center'>Find the studio entrance</span>
                            <img src={home4} className='studio-outside-sizing' alt="home4"/>
                            <span className='text-center center'>You can knock the door at the time of your reservation.</span>
                            
                        </div>
                    </div>
    
                </div>
    
            </React.Fragment>
        );
    }

    else if (width < 1400) {

        return (
            <React.Fragment>
    
                <div className='studio-mandala-container flex-column'>
    
                    <div className='flex-column text-container'>
                        <span className='recoleta medium'> Welcome to my Home Studio</span>
                        <span>HOME is where my HEART is and the hearts of those I CARE and TRUST.</span>
                    </div>
    
                    <div className='flex-row instructions-container instructions-resize' ref={instructionsRef}>
                        <div className='flex-column picture-container'>
    
                            <span className='recoleta instructions-size text-center center'>Locate my home</span>
                            <img src={home1} className='studio-outside-sizing' alt="home1"/>
                            <span className='text-center center'>You'll see the house number on the mailbox.</span>
    
                        </div>
    
                        <div className='flex-column picture-container'>
    
                            <span className='recoleta instructions-size text-center center'>Go head and drive down the driveway</span>
                            <img src={home2} className='studio-outside-sizing' alt="home2"/>
                            <span className='text-center center'>You can park there, where the car in the picture is. There is plenty of parking space
                             for you to "U" turn or backup.</span>
                            
                        </div>
                    </div>
    
                    <div className='flex-row instructions-container instructions-resize'>
                        <div className='flex-column picture-container'>
                            
                            <span className='recoleta instructions-size text-center center'>See the house's deck on your left side</span>
                            <img src={home3} className='studio-outside-sizing' alt="home3"/>
                            <span className='text-center center'>Walk under the deck and through the patio.</span>
    
                        </div>
    
                        <div className='flex-column picture-container'>
    
                            <span className='recoleta instructions-size text-center center'>Find the studio entrance</span>
                            <img src={home4} className='studio-outside-sizing' alt="home4"/>
                            <span className='text-center center'>You can knock the door at the time of your reservation.</span>
                            
                        </div>
                    </div>
    
                </div>
    
            </React.Fragment>
        );

    }

    else {

        return (
            <React.Fragment>

                <div className='studio-mandala-container flex-column'>

                    <div className='flex-column text-container'>
                        <span className='recoleta medium'> Welcome to my Home Studio</span>
                        <span>HOME is where my HEART is and the hearts of those I CARE and TRUST.</span>
                    </div>

                    <div className='flex-row instructions-container' ref={instructionsRef}>
                        <div className='flex-column picture-container'>

                            <span className='recoleta instructions-size text-center center'>Locate my home</span>
                            <img src={home1} className='studio-outside-sizing' alt="home1"/>
                            <span className='text-center center'>You'll see the house number on the mailbox.</span>

                        </div>

                        <div className='flex-column picture-container'>

                            <span className='recoleta instructions-size text-center center'>Go head and drive down the driveway</span>
                            <img src={home2} className='studio-outside-sizing' alt="home2"/>
                            <span className='text-center center'>You can park there, where the car in the picture is. There is plenty of parking space
                            for you to "U" turn or backup.</span>
                            
                        </div>

                        <div className='flex-column picture-container'>
                            
                            <span className='recoleta instructions-size text-center center'>See the house's deck on your left side</span>
                            <img src={home3} className='studio-outside-sizing' alt="home3"/>
                            <span className='text-center center'>Walk under the deck and through the patio.</span>

                        </div>

                        <div className='flex-column picture-container'>

                            <span className='recoleta instructions-size text-center center'>Find the studio entrance</span>
                            <img src={home4} className='studio-outside-sizing' alt="home4"/>
                            <span className='text-center center'>You can knock the door at the time of your reservation.</span>
                            
                        </div>
                    </div>

                </div>

            </React.Fragment>
        );
    }

}

export default StudioOutside
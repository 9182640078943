import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { isFreeSlotValidAsync, createGoogleEventAsync, addEmailAsync, addIntakeFormAsync, addClientAsync, addAppointmentAsync, updateIntakeFormAsync } from '../../store/bookingSystemSlice';
import moment from 'moment';


function BookAppointment() {

    const dispatch = useDispatch();
    const { selectedService, yogassageType, selectedDate, selectedTime, firstName, lastName, email, phone, 
            reasons, reasonsExplanation, medicalConditions, medicalExplanation, activitiesExplanation, googleEventId, emailId, intakeFormId, clientId, intakeFormChanged, appointmentAdded } = useSelector(state => state.bookingSystem);


    useEffect(() => {
        if (googleEventId === null)
            dispatch(createGoogleEventAsync(selectedDate, selectedTime, selectedService, yogassageType, firstName, lastName));

        else if (emailId === null)
            dispatch(addEmailAsync(email));

        else if (intakeFormId === null)
            dispatch(addIntakeFormAsync(reasons, reasonsExplanation, medicalConditions, medicalExplanation, activitiesExplanation));

        else if (intakeFormChanged)
            dispatch(updateIntakeFormAsync(intakeFormId, reasons, reasonsExplanation, medicalConditions, medicalExplanation, activitiesExplanation));

        else if (clientId === null)
            dispatch(addClientAsync(emailId, intakeFormId, firstName, lastName, phone));

        else if (appointmentAdded === false)
            dispatch(addAppointmentAsync(clientId, googleEventId, selectedTime, selectedDate, selectedService, yogassageType, firstName, lastName, email, phone, reasons, reasonsExplanation, medicalConditions, medicalExplanation, activitiesExplanation));
        

        
    }, [selectedDate, selectedTime, selectedService, yogassageType, firstName, lastName, googleEventId, reasons, reasonsExplanation, medicalConditions,
        medicalExplanation, activitiesExplanation, emailId, intakeFormId, email, phone, clientId, intakeFormChanged, appointmentAdded, dispatch]);

    return (
        <React.Fragment>
            <span>You have been sent a confirmation email to {email}.</span>
            <span className="bold">Service:</span>
            <span>{`${selectedService} ${(selectedService === "Yogassage Therapy") ? (yogassageType) : ('')}`}</span>
            <span className="bold">Date:</span>
            <span>{moment(selectedDate).format('MMM DD, YYYY')}</span>
            <span className="bold">Time:</span>
            <span>{selectedTime}</span>
            <span className="bold">Client:</span>
            <span>{firstName} {lastName}</span>
            <span>{email}</span>
            <span>{phone}</span>
        </React.Fragment>
    );
}

function Summary() {

    const dispatch = useDispatch();
    const { appointmentValid, selectedService, selectedDate, selectedTime, yogassageType } = useSelector(state => state.bookingSystem);

    useEffect(() => {
        if (appointmentValid === null)
            dispatch(isFreeSlotValidAsync(selectedService, selectedDate, selectedTime, yogassageType));
    }, [dispatch, appointmentValid, selectedService, selectedDate, selectedTime, yogassageType]);


    return(
        <React.Fragment>

            <span className='bold medium'>Summary</span>

            {(appointmentValid === null) ? <span>Checking if the appointment is still valid</span> : 
             (appointmentValid === true) ? <BookAppointment/> : <span>The appointment is not valid anymore.</span>}

        </React.Fragment>
    );

}

export default Summary;
import React from 'react';
import './TraditionalMassage.css';
import traditional_massage from '../../images/traditional-massage.jpeg';
import elbow_massage from '../../images/elbow-massage.jpeg';
import useWindowDimensions from '../../components/getWindowDimensions';

function TraditionalMassagePictures() {

    const width = useWindowDimensions().width;

    return(
        <div className='flex-column center fade-outer'>
            <img src={traditional_massage} className={`rounded-borders ${(width < 500) ? 'trad-massage-img-mobile' : 'trad-massage-img'}`} alt='img1'/>
            <img src={elbow_massage} className={`fade-inner top rounded-borders ${(width < 500) ? 'trad-massage-img-mobile' : 'trad-massage-img'}`} alt='img2'/>
        </div>
    );

}

function TraditionalMassage() {

    const width = useWindowDimensions().width;

    if (width < 900) {
        return (
            <React.Fragment>
                <div className='container flex-column'>

                    <div>
                        <span className='recoleta medium text-center center'>Traditional Massage</span>
                        <br/>
                        <span className='text-center center line-height'>
                            For this service I'll use my hands, forearms, elbows and up to ⅓ of my body weight to apply wide range of pressures,
                            techniques and modalities to provide an overall wellness massage that is customized to your specific needs. This massage
                            will calm your mind, gently detoxifying your body and relieve general muscle tension and soreness.
                        </span>
                    </div>

                    <TraditionalMassagePictures/>

                </div>
            </React.Fragment>
        );
    }
    else {
        return (
            <React.Fragment>
                <div className='container flex-row'>

                    <TraditionalMassagePictures/>

                    <div className='flex-column justify-content-center'>
                        <span className='recoleta medium text-center center'>Traditional Massage</span>
                        <br/>
                        <span className='text-center center line-height'>
                            For this service I'll use my hands, forearms, elbows and up to ⅓ of my body weight to apply wide range of pressures,
                            techniques and modalities to provide an overall wellness massage that is customized to your specific needs. This massage
                            will calm your mind, gently detoxifying your body and relieve general muscle tension and soreness.
                        </span>
                    </div>

                </div>
            </React.Fragment>
        );
    }

}

export default TraditionalMassage
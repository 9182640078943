import React, { useEffect } from "react";
import MultiOptionCheckBox from "../../components/MultiOptionCheckBox";
import { useSelector, useDispatch } from 'react-redux';
import { doesClientExistAsync, setReasons, setReasonsExplanation, setMedicalConditions, setMedicalExplanation, setActivitiesExplanation, setIntakeFormExists, setIntakeFormChanged } from '../../store/bookingSystemSlice';

    // explanation only required if specific problem selected
    function ReasonsExplanation() {
        const dispatch = useDispatch();
        const { reasons, reasonsExplanation } = useSelector(state => state.bookingSystem);

        if (reasons["Specific Problem"] === true) {
            return <textarea rows='3' className="enter-details-input enter-details-long-text" type="text" spellCheck={false} placeholder='Explain the above answer *' 
                    value={reasonsExplanation} onChange={e => dispatch(setReasonsExplanation(e.target.value))}/>;
        }
        else {
            // dispatch(setReasonsExplanation(""));
            return <br/>;
        }
    }

    // explanation only required if medical condition selected
    function MedicalExplanation() {

        const dispatch = useDispatch();
        const { medicalConditions, medicalExplanation } = useSelector(state => state.bookingSystem);

        if (Object.keys(medicalConditions).some((key) => {return key !== "None of the above" && medicalConditions[key]})) {
            return <textarea rows='3' className="enter-details-input enter-details-long-text" type="text" spellCheck={false} placeholder='Explain the above answer *' 
                value={medicalExplanation} onChange={e => dispatch(setMedicalExplanation(e.target.value))}/>;
        }
        else {
            return <></>;
        }
    }

function IntakeForm() {

    // redux states
    const dispatch = useDispatch();
    const { intakeFormExists, firstName, lastName, email, phone, reasons, medicalConditions, activitiesExplanation, intakeFormId } = useSelector(state => state.bookingSystem);

    // check if they are returning client
    useEffect(() => {
        if (intakeFormExists === null) {
            dispatch(doesClientExistAsync(firstName, lastName, email, phone));
        }
            
    }, [intakeFormExists, dispatch ,firstName, lastName, email, phone, intakeFormId]);

    const handleReasonsChange = (selectedOptions) => {
        const updatedReasons = selectedOptions.reduce((result, isSelected, index) => {
          result[Object.keys(reasons)[index]] = isSelected;
          return result;
        }, {});
        dispatch(setReasons(updatedReasons));
    }

    const handleMedicalConditionsChange = (selectedOptions) => {
        const updatedMedicalConditions = selectedOptions.reduce((result, isSelected, index) => {
          result[Object.keys(medicalConditions)[index]] = isSelected;
          return result;
        }, {});
        dispatch(setMedicalConditions(updatedMedicalConditions));
    }

    const handleYesButton = () => {
        dispatch(setIntakeFormExists(false));
        dispatch(setIntakeFormChanged(true));
    }

    if (intakeFormExists === null) {
        return (
            <span>Checking if client exists...</span>
        );
    }

    else if (intakeFormExists === true) {
        return(
            <React.Fragment>
                <span className='bold medium'>Intake Form</span>
                <span>You've filled out the Intake Form, would you like to make any changes?</span>
                <div className='continue-button' onClick={handleYesButton}>
                    <span>Yes</span>
                </div>
            </React.Fragment>
        );
    }

    else if (intakeFormExists === false) {
        return (
            <React.Fragment>

                <span className='bold medium'>Intake Form</span>

                <span>Reasons for seeking Massage Therapy: <span style={{color: 'red'}}>*</span> </span>
                <MultiOptionCheckBox options={Object.keys(reasons)} selectedOptions={Object.values(reasons)} setSelectedOptions={handleReasonsChange}/>

                <ReasonsExplanation/>

                <span>Do you have any of the following: <span style={{color: 'red'}}>*</span> </span>
                <MultiOptionCheckBox options={Object.keys(medicalConditions)} selectedOptions={Object.values(medicalConditions)} setSelectedOptions={handleMedicalConditionsChange}/>

                <MedicalExplanation/>

                <br/>

                <textarea rows='3' className="enter-details-input enter-details-long-text" type="text" spellCheck={false} placeholder='What are the activities you do daily for prolonged hours? (Like occupation, hobbies, sports...) *'
                value={activitiesExplanation} onChange={e => dispatch(setActivitiesExplanation(e.target.value))}/>
                
            </React.Fragment>           
        );
    }

}

export default IntakeForm;
import './App.css';
import React, { useState } from 'react';
import NavBar from './pages/NavBar/NavBar';
import Home from './pages/HomeTab/Home';
import AboutMe from './pages/AboutMeTab/AboutMe';
import useWindowDimensions from './components/getWindowDimensions';
import BurgerMenu from './pages/NavBar/BurgerMenu'
import MassageTherapyHome from './pages/MassageTherapyTab/MassageTherapyHome';
import TraditionalMassage from './pages/MassageTherapyTab/TraditionalMassage';
import AshiatsuMassage from './pages/MassageTherapyTab/AshiatsuMassage';
import CouplesMassage from './pages/MassageTherapyTab/CouplesMassage';
import YogaHome from './pages/YogaTab/YogaHome';
import YogaGroup from './pages/YogaTab/YogaGroup';
import EventsRequest from './pages/EventsTab/EventsRequest';
import StudioOutside from './pages/StudioTab/StudioOutside';
import StudioInside from './pages/StudioTab/StudioInside';
import FAQ from './pages/FAQTab/FAQ';
import BookingSystem from './pages/BookingSystem/BookingSystem';
import { resetState } from './store/bookingSystemSlice';
import { useDispatch } from 'react-redux';
import ThaiMassage from './pages/MassageTherapyTab/ThaiMassage';
import YogassageTherapy from './pages/YogassageTherapyTab/YogassageTherapy';
import Footer from './components/Footer';
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';
import useRedirectToHttps from './components/UseRedirectToHttps';

export const Tabs = {
  Home: 0,
  AboutMe: 1,
  MassageTherapy_Home: 2,
  MassageTherapy_Trad: 3,
  MassageTherapy_Ash: 4,
  MassageTherapy_Thai: 5,
  MassageTherapy_Coup: 6,
  MassageTherapy_FAQ: 7,
  Yoga_Home: 8,
  Yoga_Private: 9,
  Yoga_Group: 10,
  // Events_Home: 11,
  Events_Request: 12,
  Studio_Outside: 13,
  Studio_Inside: 14,
  FAQ: 15,
  Yogassage: 16
}

function NavigationBar({setCurrentTab, handleBookingSystemOpenClick, bookingSystemActive}) {

  const { width } = useWindowDimensions();

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false)

  const handleIsOpen = () => {
    setBurgerMenuOpen(!burgerMenuOpen)
  }

  const closeSideBar = () => {
    setBurgerMenuOpen(false)
  }

  React.useEffect(() => {
    if (burgerMenuOpen) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }
  }, [burgerMenuOpen]);

  if (width > 1350) {
    return <NavBar setCurrentTab={setCurrentTab} handleBookingSystemOpenClick={handleBookingSystemOpenClick} width={width}/>;
  }
  else {
    return (
      <React.Fragment>
        {(bookingSystemActive) ? <></> : <BurgerMenu setCurrentTab={setCurrentTab} handleBookingSystemOpenClick={handleBookingSystemOpenClick} isOpen={burgerMenuOpen} handleIsOpen={handleIsOpen} closeSideBar={closeSideBar} />}
        <NavBar width={width}/>
      </React.Fragment>
    );
  }
}

function CurrentPage({currentTab, setCurrentTab, handleBookingSystemOpenClick}) {

  const { width } = useWindowDimensions();

  switch(currentTab) {
    case Tabs.Home:
      return <Home handleBookingSystemOpenClick={handleBookingSystemOpenClick} width={width}/>;

    case Tabs.AboutMe:
      return <AboutMe/>;

    case Tabs.MassageTherapy_Home:
      return <MassageTherapyHome setCurrentTab={setCurrentTab}/>;

    case Tabs.MassageTherapy_Trad:
      return <TraditionalMassage/>;

    case Tabs.MassageTherapy_Ash:
      return <AshiatsuMassage/>;

    case Tabs.MassageTherapy_Thai:
      return <ThaiMassage/>;

    case Tabs.MassageTherapy_Coup:
      return <CouplesMassage handleBookingSystemOpenClick={handleBookingSystemOpenClick}/>;

    case Tabs.Yoga_Home:
      return <YogaHome/>;

    // case Tabs.Yoga_Private:
    //   return <YogaPrivate/>;

    case Tabs.Yoga_Group:
      return <YogaGroup/>;

    // case Tabs.Events_Home:
    //   return <EventsHome/>;

    case Tabs.Events_Request:
      return <EventsRequest/>;

    case Tabs.Studio_Outside:
      return <StudioOutside/>;

    case Tabs.Studio_Inside:
      return <StudioInside/>;

    case Tabs.FAQ:
      return <FAQ/>;

    case Tabs.Yogassage:
      return <YogassageTherapy handleBookingSystemOpenClick={handleBookingSystemOpenClick}/>;

    default:
      return <Home/>;
  }
}  

function App() {

  useRedirectToHttps();

  const { width } = useWindowDimensions();

  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(Tabs.Home);

  const [bookingSystemActive, setBookingSystemActive] = useState(false);

  const navigate = useNavigate(); // this is used for bookingSystemActive in URL
  const [searchParams] = useSearchParams();


  React.useEffect(() => {
    if (bookingSystemActive) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }
  }, [bookingSystemActive]);

  React.useEffect(() => {
    setBookingSystemActive(searchParams.get('bookingSystemActive') === 'true');
  }, [searchParams]);


  const handleBookingSystemOpenClick = () => {
    setBookingSystemActive(true);
    navigate(`/?${createSearchParams({ bookingSystemActive: true })}`, { replace: true });
  }

  const handleBookingSystemCloseClick = () => {
    setBookingSystemActive(false);
    dispatch(resetState());
    navigate('/', { replace: true });
  }

  return(
    <div>
      <NavigationBar setCurrentTab={setCurrentTab} handleBookingSystemOpenClick={handleBookingSystemOpenClick} bookingSystemActive={bookingSystemActive}/>
      <CurrentPage currentTab={currentTab} setCurrentTab={setCurrentTab} handleBookingSystemOpenClick={handleBookingSystemOpenClick} width={width}/>
      {(bookingSystemActive) ? <BookingSystem handleCloseClick={handleBookingSystemCloseClick} bookingSystemActive={bookingSystemActive}/> : <></>}
      <Footer width={width}/>
    </div>
  );

}
export default App;

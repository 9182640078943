import React from 'react';
import useWindowDimensions from '../../components/getWindowDimensions';
import thai_massage from '../../images/thai-massage.webp';
import thai_massage_2 from '../../images/thai-massage-2.jpeg';
import './ThaiMassage.css';

function ThaiMassagePictures() {

    const width = useWindowDimensions().width;
    
    return(
        <div className='flex-column center fade-outer'>
            <img src={thai_massage} className={`rounded-borders ${(width < 500) ? 'thai-img-mobile' : 'thai-img'}`} alt='img1'/>
            <img src={thai_massage_2} className={`fade-inner top rounded-borders ${(width < 500) ? 'thai-img-mobile' : 'thai-img'}`} alt='img2'/>
        </div>
    );

}

function ThaiMassage() {

    const width = useWindowDimensions().width;


    if (width < 1200) {
        return (
            <React.Fragment>
                <div className='container flex-column'>

                    <div>
                        <span className='recoleta medium text-center center'>Thai Massage</span>
                        <br/>
                        <span className='text-center center line-height'>
                            This full body treatment is offered while fully clothed. I use my hands, forearms, elbows, knees, feet and up to my 
                            full body weight to provide this service by positioning my client into passive and assisted yoga stretches and applying 
                            rhythmic compression and other techniques that releases tension from deep connective tissues, like fascia, ligaments, joints, and bones.
                            This service is recommended for someone needing to improve overall flexibility and stretch or relieve tightness in commonly used muscle groups.
                        </span>
                        <br/>
                        <span className='text-center center line-height'>NOTE: Please make sure to wear comfortable stretchy clothes for this session as you'll be fully clothed all the time.</span>
                    </div>

                    <ThaiMassagePictures/>

                </div>
            </React.Fragment>
        );
    }
    else {
        return (
            <React.Fragment>
                <div className='container flex-row'>

                    <ThaiMassagePictures/>

                    <div className='flex-column justify-content-center'>
                        <span className='recoleta medium text-center center'>Thai Massage</span>
                        <br/>
                        <span className='text-center center line-height'>
                            This full body treatment is offered while fully clothed. I use my hands, forearms, elbows, knees, feet and up to my 
                            full body weight to provide this service by positioning my client into passive and assisted yoga stretches and applying 
                            rhythmic compression and other techniques that releases tension from deep connective tissues, like fascia, ligaments, joints, and bones.
                            This service is recommended for someone needing to improve overall flexibility and stretch or relieve tightness in commonly used muscle groups.
                        </span>
                        <br/>
                        <span className='text-center center line-height'>NOTE: Please make sure to wear comfortable stretchy clothes for this session as you'll be fully clothed all the time.</span>
                    </div>

                </div>
            </React.Fragment>
        );
    }

}

export default ThaiMassage;
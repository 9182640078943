import React from 'react';
import Calendar from 'react-calendar';
import './BookingSystem.css';
import 'react-calendar/dist/Calendar.css';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedDate, setSelectedTime } from '../../store/bookingSystemSlice';
import moment from 'moment';



function CalendarComponent() {

    // redux states
    const dispatch = useDispatch();
    const { selectedDate, freeSlots, minDate, maxDate } = useSelector(state => state.bookingSystem);

    function tileClassName({ date }) {

        const formattedDate = moment(date, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('MM-DD-YYYY');

        // Check if a date React-Calendar wants to check is on the list of dates to add class to
        if (freeSlots.find(fsDate => fsDate.date === formattedDate)) {
          return 'available-day';
        }
        return 'unavailable-day'; 
    }
    
    // disables all days other than the available days in free slots
    function tileDisabled({ date }) {
        const formattedDate = moment(date, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('MM-DD-YYYY');
        return !freeSlots.find(fsDate => fsDate.date === formattedDate);
    }

    return (
        <Calendar
        onChange={(date) => {
            if (date.getTime() !== selectedDate) {
                dispatch(setSelectedTime(null))
                dispatch(setSelectedDate(date.getTime()))
            }
        }}
        value={(moment.unix(selectedDate/1000).toDate())}
        prev2Label={null}
        next2Label={null}
        minDate={(moment.unix(minDate/1000).toDate())}
        maxDate={(moment.unix(maxDate/1000).toDate())}
        navigationLabel={({ label }) => (
        <div>{label}</div>
        )}
        tileClassName={tileClassName}
        tileDisabled={tileDisabled}
        showNeighboringMonth={false}
        calendarType={'US'}
        />
    );

}

export default CalendarComponent;
import React from 'react';
import './CouplesMassage.css';
import studio from '../../images/studio.webp';
// uses css from TraditionalMassage.css
import useWindowDimensions from '../../components/getWindowDimensions';


function CouplesMassage({handleBookingSystemOpenClick}) {

    const width = useWindowDimensions().width;

    if (width < 1060) {

        return (
            <React.Fragment>
                <div className='couples-container couples-container-resize flex-column'>

                    <div className='flex-column align-items-center'>
                        <span className='recoleta medium center'>A time to Receive and ...</span>
                        <br/>
                        <span className='recoleta medium'>... a time to Relax</span>
                        <br/>
                        <span className='center text-center'>These are two consecutive Customized Therapeutic Massage Sessions of one hour each. 
                            A variety of modalities, techniques and tools including Swedish, Deep Tissue, Ashiatsu, 
                            Stones and Cupping may incorporated into these sessions. One of the couples will receive 
                            a massage while the other couple relax in the Relaxation Area, then we switch allowing the 
                            one that was relaxing to receive a massage and the one that received will relax in the Relaxation Area.
                        </span>
                        <br/>
                        <span className='center text-center'>Please note, there is a private bathroom for changing but the Relaxation Area is inside the treatment room.</span>
                        <br/>
                        <div className='couples-book' onClick={handleBookingSystemOpenClick}>
                            <span>Book a Couples Massage</span>
                        </div>
                    </div>

                    <img src={studio} className='studio-img-resize rounded-borders' alt='studio'/>

                </div>
            </React.Fragment>
        );

    }

    else {

        return (
            <React.Fragment>
                <div className='couples-container flex-row'>

                    <img src={studio} className='studio-img rounded-borders' alt='studio'/>

                    <div className='flex-column justify-content-center align-items-center'>
                        <span className='recoleta medium center text-center'>A time to Receive and ...</span>
                        <br/>
                        <span className='recoleta medium center text-center'>... a time to Relax</span>
                        <br/>
                        <span className='center text-center line-height'>These are two consecutive Customized Therapeutic Massage Sessions of one hour each. 
                            A variety of modalities, techniques and tools including Swedish, Deep Tissue, Ashiatsu, 
                            Stones and Cupping may incorporated into these sessions. One of the couples will receive 
                            a massage while the other couple relax in the Relaxation Area, then we switch allowing the 
                            one that was relaxing to receive a massage and the one that received will relax in the Relaxation Area.
                        </span>
                        <br/>
                        <span className='center text-center line-height'>Please note, there is a private bathroom for changing but the Relaxation Area is inside the treatment room.</span>
                        <br/>
                        <div className='couples-book' onClick={handleBookingSystemOpenClick}>
                            <span>Book a Couples Massage</span>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );

    }   

}

export default CouplesMassage
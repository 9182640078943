import React from 'react';
import './BookingSystem.css';
import { BookingStates } from './BookingSystem';
import { useSelector } from 'react-redux';
import { EnterDetailsStages } from '../../store/bookingSystemSlice';

function ContinueBar({ handleContinueClick, handleBackClick, bookingState, handleCloseClick }) {

    const { selectedService, selectedTime, enterDetailsStage, firstName, lastName, email, phone, invalidEmail, invalidPhone, 
            reasons, medicalConditions, reasonsExplanation, medicalExplanation, activitiesExplanation, terms, intakeFormExists } = useSelector(state => state.bookingSystem);

    const userNotReady = () => {
        if (bookingState === BookingStates.SelectService && selectedService === null) {
            return true;
        }
        else if (bookingState === BookingStates.SelectDateAndTime && selectedTime === null) {
            return true;
        }
        else if ((bookingState === BookingStates.EnterDetails) && (enterDetailsStage === EnterDetailsStages.UserInfo)) {
            if (firstName.length < 2 || lastName.length < 2 || invalidEmail || invalidPhone || email.length === 0 || phone.length === 0) {
                return true;
            }
        }
        else if ((bookingState === BookingStates.EnterDetails) && (enterDetailsStage === EnterDetailsStages.IntakeForm)) {
            if (intakeFormExists) {
                return false;
            }
            if (areAllKeysFalse(reasons) || areAllKeysFalse(medicalConditions) || activitiesExplanation === "") {
                return true;
            }

            if (reasons["Specific Problem"] === true && reasonsExplanation === "") {
                return true;
            }

            if (Object.keys(medicalConditions).some((key) => {return key !== "None of the above" && medicalConditions[key]}) && medicalExplanation === "") {
                return true;
            }
        }
        else if ((bookingState === BookingStates.EnterDetails) && (enterDetailsStage === EnterDetailsStages.Terms)) {
            if (terms["I completed this form to the best of my knowledge."] === false || terms["I will inform to my massage therapist of any health or medical changes."] === false || 
                terms["I understand that there are benefits and risks of massage therapy."] === false) {
                    return true;
            }
        }

        return false;
    }


    function areAllKeysFalse(obj) {
        for (let key in obj) {
          if (obj[key]) {
            return false;
          }
        }
        return true;
    }

    const ContinueButton = () => {
        // if user is ready to book appointment
        if((bookingState === BookingStates.EnterDetails) && (enterDetailsStage === EnterDetailsStages.Terms)) {
            if (userNotReady()) {
                return (
                    <div className='continue-button continue-button-not-ready' onClick={handleContinueClick}>
                        <span className='continue-button-text-not-ready'>Book Appointment</span>
                    </div>
                );
            }
            else {
                return (
                    <div className='continue-button' onClick={handleContinueClick}>
                        <span>Book Appointment</span>
                    </div>
                );
            }
        }
        // if user is not ready to continue to next step - gray out the button
        else if(userNotReady()) {
            return(
                <div className='continue-button continue-button-not-ready'>
                    <span className='continue-button-text-not-ready'>Continue</span>
                </div>
            );
        }
        // if user is ready for the next step
        else {
            return (
            <div className='continue-button' onClick={handleContinueClick}>
                <span>Continue</span>
            </div>
            );
        }
    }

    if (bookingState === BookingStates.SelectService) {
        return (
            <div className='continue-bar-container'>

                {/* HORIZONTAL LINE */}
                <div style={{width: '100%'}}>
                    <hr style={{width: '100%', border: '1px solid black'}}/>
                </div>

                <ContinueButton/>

            </div>
        );
    }
    else if (bookingState === BookingStates.SelectDateAndTime || (bookingState === BookingStates.EnterDetails && enterDetailsStage !== EnterDetailsStages.Summary))  {
        return (
            <div className='continue-bar-container'>

                {/* HORIZONTAL LINE */}
                <div style={{width: '100%'}}>
                    <hr style={{width: '100%', border: '1px solid black'}}/>
                </div>

                <div className='flex-row continue-back-container'>
                    <div className='back-button' onClick={handleBackClick}>
                        <span>Back</span>
                    </div>

                    <ContinueButton/>
                </div>

            </div>
        );
    }
    else if (bookingState === BookingStates.EnterDetails && enterDetailsStage === EnterDetailsStages.Summary) {
        return (
            <div className='continue-bar-container'>

                {/* HORIZONTAL LINE */}
                <div style={{width: '100%'}}>
                    <hr style={{width: '100%', border: '1px solid black'}}/>
                </div>

                <div className='continue-button' onClick={handleCloseClick}>
                    <span>Close</span>
                </div>

            </div>
        );
    }
}

export default ContinueBar;
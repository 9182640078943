import React from 'react';
import './YogaTab.css';
import yoga from '../../images/yoga.jpeg';
import useWindowDimensions from '../../components/getWindowDimensions';

function YogaGroup() {

    const width = useWindowDimensions().width;

    if (width < 1060) {

        return (
            <React.Fragment>
                <div className='couples-container couples-container-resize flex-column'>

                    <div className='flex-column justify-content-center align-items-center'>
                        <span className='recoleta medium center text-center'>Hatha Yoga "Flow and Hold"</span>
                        <br/>
                        <span className='recoleta center text-center line-height'>Wednesdays 9:15-10:15 am (Mind and Body Studio)</span>
                        <br/>
                        <span className='center text-center'>
                        A slow flow with a brief hold. A class that promotes balance, strength and flexibility. Every class is different,
                        each one working toward different and specific physical and energetic benefits.
                        </span>
                        <br/>

                        <div className='flex-row yoga-group-buttons-container'>
                        <div className='couples-book' onClick={() => {window.open('https://ymcanwnc.org/locations/robinhood-road-family-ymca', '_blank')}}>
                            <span>Location</span>
                        </div>
                            <div className='couples-book'
                                 onClick={() => {window.open('https://ymcanwnc.org/about-us/y-mobile-app')}}>
                                <span>Sign Up App</span>
                            </div>
                        </div>

                    </div>

                    <img src={yoga} className='yoga-home-img-resize rounded-borders' alt='yoga'/>

                </div>
            </React.Fragment>
        );

    }

    else {

        return (
            <React.Fragment>
                <div className='couples-container flex-row'>

                    <img src={yoga} className='yoga-home-img-size rounded-borders' alt='yoga'/>

                    <div className='flex-column justify-content-center align-items-center'>
                        <span className='recoleta medium center text-center'>Hatha Yoga "Flow and Hold"</span>
                        <br/>
                        <span className='recoleta center text-center line-height'>Wednesdays 9:15-10:15 am (Mind and Body Studio)</span>
                        <br/>
                        <span className='center text-center'>
                        A slow flow with a brief hold. A class that promotes balance, strength and flexibility. Every class is different,
                        each one working toward different and specific physical and energetic benefits.
                        </span>
                        <br/>

                        <div className='flex-row yoga-group-buttons-container'>
                            <div className='couples-book' onClick={() => {window.open('https://ymcanwnc.org/locations/robinhood-road-family-ymca', '_blank')}}>
                                <span>Location</span>
                            </div>
                            <div className='couples-book' onClick={() => {window.open('https://ymcanwnc.org/about-us/y-mobile-app')}}>
                                <span>Sign Up App</span>
                            </div>
                        </div>

                    </div>

                </div>
            </React.Fragment>
        );

    }  
}

export default YogaGroup
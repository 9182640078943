import React from 'react';
import './YogassageTherapy.css';
import asanas from '../../images/asanas.jpeg';
import restorative from '../../images/restorative.jpeg';
import thai from '../../images/thai.jpeg';
import meditation from '../../images/yoga-private.jpeg';
import useWindowDimensions from '../../components/getWindowDimensions';


function YogassageTherapy({handleBookingSystemOpenClick}) {

    const width = useWindowDimensions().width;

    function Pictures() {
        if (width > 1160) {
            return (
                <div className='yogassage-pictures-container flex-row'>
                
                    <div className='yogassage-text-col-container'> 
                        <div className='yogassage-img-container'>
                            <img src={asanas} className='img-sizing' alt='asanas'/>
                        </div>
                        <span className='center text-center'>Asanas</span>
                    </div>

                    <div className='yogassage-text-col-container'> 
                        <div className='yogassage-img-container'>
                            <img src={restorative} className='img-sizing' alt='restorative'/>
                        </div>
                        <span className='center text-center'>Restorative</span>
                    </div>

                    <div className='yogassage-text-col-container'> 
                        <div className='yogassage-img-container'>
                            <img src={thai} className='img-sizing' alt='thai'/>
                        </div>
                        <span className='center text-center'>Thai Massage</span>
                    </div>

                    <div className='yogassage-text-col-container'> 
                        <div className='yogassage-img-container'>
                            <img src={meditation} className='img-sizing' alt='meditation'/>
                        </div>
                        <span className='center text-center'>Meditation, Mudras, Mantras and Guided Visualization</span>
                    </div>

                </div>
            );
        }

        else if (width < 600) {
            return (
                <div className='yogassage-pictures-container flex-column align-items-center'>
                    <div className='yogassage-text-col-container'> 
                            <div className='yogassage-img-container'>
                                <img src={asanas} className='img-sizing' alt='asanas'/>
                            </div>
                            <span className='center text-center'>Asanas</span>
                    </div>

                    <div className='yogassage-text-col-container'> 
                            <div className='yogassage-img-container'>
                                <img src={restorative} className='img-sizing' alt='restorative'/>
                            </div>
                            <span className='center text-center'>Restorative</span>
                    </div>

                    <div className='yogassage-text-col-container'> 
                        <div className='yogassage-img-container'>
                            <img src={thai} className='img-sizing' alt='thai'/>
                        </div>
                        <span className='center text-center'>Thai Massage</span>
                    </div>

                    <div className='yogassage-text-col-container'> 
                        <div className='yogassage-img-container'>
                            <img src={meditation} className='img-sizing' alt='meditation'/>
                        </div>
                        <span className='center text-center'>Meditation, Mudras, Mantras and Guided Visualization</span>
                    </div>
                </div>
            );
        }

        else {
            return (
                <div className='yogassage-pictures-resize-container'>
                    <div className='yogassage-pictures-container flex-row'>
                    
                        <div className='yogassage-text-col-container'> 
                            <div className='yogassage-img-container'>
                                <img src={asanas} className='img-sizing' alt='asanas'/>
                            </div>
                            <span className='center text-center'>Asanas</span>
                        </div>

                        <div className='yogassage-text-col-container'> 
                            <div className='yogassage-img-container'>
                                <img src={restorative} className='img-sizing' alt='restorative'/>
                            </div>
                            <span className='center text-center'>Restorative</span>
                        </div>

                    </div>

                    <div className='yogassage-pictures-container flex-row'>
                                    
                    <div className='yogassage-text-col-container'> 
                        <div className='yogassage-img-container'>
                            <img src={thai} className='img-sizing' alt='thai'/>
                        </div>
                        <span className='center text-center'>Thai Massage</span>
                    </div>

                    <div className='yogassage-text-col-container'> 
                        <div className='yogassage-img-container'>
                            <img src={meditation} className='img-sizing' alt='meditation'/>
                        </div>
                        <span className='center text-center'>Meditation, Mudras, Mantras and Guided Visualization</span>
                    </div>

                    </div>
                </div>
            );
        }
    }

    return (
        <div className='yogassage-container'>

            <div className='yogassage-title-container'>

                <span className='recoleta medium center'>Yogassage Therapy</span>

                <span className='recoleta center text-center yogassage-subtitle'>The perfect therapeutic treatment... A combination of Yoga and Massage</span>

            </div>

            <Pictures/>

            <div className='yogassage-mandala-container'>

                <div className='yogassage-description-container flex-column'>

                    <span className={`recoleta yogassage-description-title ${(width < 600) ? 'center text-center' : ''}`}>What is Yogassage Therapy?</span>
                    <br/>

                    <span className='yogassage-text'>In order for healing to be successful, all aspects of a person should be addressed and integrated to create and maintain an optimal state of physical,
                        emotional, mental and spiritual health.</span>
                    <br/>

                    <span className='yogassage-text'>The benefits of Private Yogassage Sessions are:</span>
                    <span className='list-item yogassage-text'>
                        <span className='bold'>Unique Schedule</span> as you can book your sessions whenever your need too.
                    </span>
                    <br/>

                    <span className='list-item yogassage-text'>
                        100% <span className='bold'>personalized instruction</span>.
                    </span>
                    <br/>

                    <span className='list-item yogassage-text'>
                        It allows for <span className='bold'>hands-on adjustments</span>.
                    </span>
                    <br/>

                    <span className='list-item yogassage-text'>
                        <span className='bold'>Customized Thai Massage</span> to your unique needs.
                    </span>
                    <br/>


                    <span className='yogassage-text'>This customized Yogassage Therapy Sessions combines and incorporate specific:</span>
                    <br/>

                    <span className='yogassage-text'>1) <span className='bold'>Yoga Instruction</span> that include:</span>
                    <span className='list-item yogassage-text'>
                        <span className='bold'>Asanas</span> (physical practices). Yoga postures can be modified to address a client’s current health status, injuries or therapeutic goals. 
                        Asana are used to help both stretch, stabilize and strengthen different parts of the body. Posture and balance will also be improved through asana practice.
                    </span>
                    <br/>

                    <span className='list-item yogassage-text'>
                        <span className='bold'>Pranayama</span> (breathing exercises). The awareness of the breath as a direct link between the mind and the body (particularly the nervous system). During inhalation 
                        freshly oxygenated blood travels from lungs to the heart. The heart pumps into every part of the body where it reaches every cell improving functioning of all body systems. 
                        Also removing toxins out from the body. The mind is calmed, helping to create feelings of inner peace, better sleep, and improving the ability to concentrate.
                    </span>
                    <br/>

                    <span className='list-item yogassage-text'>
                        <span className='bold'>Meditation</span> techniques offer us a way to gently enquirer into the nature of our minds and to see when they may be working against us through repetitive, negative thought-patterns,
                        turbulent emotional states or self-sabotaging reflex reactions. Learning how to ‘befriend’ and quiet down our minds so that we can develop greater insight and perspective. Meditation
                        and relaxation practices also calms down our nervous system and that is a very powerful tool in managing chronic health conditions that are caused by stress.
                    </span>
                    <br/>

                    <span className='list-item yogassage-text'>
                        <span className='bold'>Mudras</span> are symbolic hand gesture used during meditation to promote focus, they allow us to direct the energy of the body in specific ways to cultivate a certain quality.
                    </span>
                    <br/>

                    <span className='list-item yogassage-text'>
                        <span className='bold'>Mantras</span> are energy-based sounds that are very powerful rewiring our brain’s stress response and helping us to manage a wide-range of health conditions including chronic pain, 
                        trauma and depression.
                    </span>
                    <br/>

                    <span className='yogassage-text'>
                        2) <span className='bold'>Therapeutic Thai Massage</span> (clothed massage)  It induce overall relaxation of mind and body. I position my client into passive and assisted yoga stretches and apply rhythmic compression and other techniques 
                        that releases tension from deep connective tissues. It improves overall flexibility, stretches and relieves tightness in commonly used muscle groups. So at the physical level, massage is incredible helpful 
                        treating chronic pain conditions, improving circulatory and lymphatic systems and detoxifying the physical body but also during massage, and increase of endorphins (the chemical that produce feelings of 
                        wellbeing and happiness) production is release from the brain and the levels of stress hormones, such as adrenalin, cortisol and norepinephrine, are also reduced. Therefore massage is incredible helpful 
                        treating mental health conditions as well like stress, improving concentration and more.
                    </span>
                    <br/>

                    <div className='book' onClick={handleBookingSystemOpenClick}>
                        <span>Book</span>
                    </div>

                </div>

            </div>

        </div>
    );

}

export default YogassageTherapy
import React from "react";
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import applepay from '../images/apple-pay.svg';
import googlepay from '../images/google-pay.svg';
import visa from '../images/visa.svg';
import mastercard from '../images/mastercard.svg';
import amex from '../images/americanexpress.svg';
import discover from '../images/discover.svg';
import jcb from '../images/jcb.svg';
import cashapp from '../images/cashapp.svg';
import venmo from '../images/venmo-icon.svg';


function Footer({width}) {

    if (width > 650) {

        return (
            <div className="footer-container flex-row">

                <div className="flex-row social-icon-container">
                    <img src={applepay} alt="applepay svg"/>
                    <img src={googlepay} alt="googlepay svg"/>
                    <img src={visa} alt="visa svg"/>
                    <img src={mastercard} alt="mastercard svg"/>
                    <img src={amex} alt="amex svg"/>
                    <img src={discover} alt="discover svg"/>
                    <img src={jcb} alt="jcb svg"/>
                    <img src={cashapp} alt="cashapp svg"/>
                    <img src={venmo} className='venmo-icon' alt="venmo svg"/>
                </div>

                <div className="flex-row social-icon-container">
                    <FontAwesomeIcon icon={faEnvelope} className='email-icon footer-icon' onClick={() => {window.open('mailto:laura.martinez.massage.yoga@gmail.com', '_blank');}}/>
                    <FontAwesomeIcon icon={faFacebook} className='facebook-icon footer-icon' onClick={() => {window.open('https://www.facebook.com/Laura.Martinez.Massage.Yoga', '_blank');}}/>
                    <FontAwesomeIcon icon={faInstagram} className='instagram-icon footer-icon' onClick={() => {window.open('https://www.instagram.com/laura.martinez.massage.yoga/', '_blank');}}/>
                    <FontAwesomeIcon icon={faLinkedin} className='linkedin-icon footer-icon' onClick={() => {window.open('https://www.linkedin.com/in/lauramartinezmassageyoga/', '_blank');}}/>
                </div>

            </div>
        );

    }

    else {

        return (
            <div className="footer-container-resize flex-column">

                <div className="flex-row social-icon-container">
                    <img src={applepay} alt="applepay svg"/>
                    <img src={googlepay} alt="googlepay svg"/>
                    <img src={visa} alt="visa svg"/>
                    <img src={mastercard} alt="mastercard svg"/>
                    <img src={amex} alt="amex svg"/>
                    <img src={discover} alt="discover svg"/>
                    <img src={jcb} alt="jcb svg"/>
                    <img src={cashapp} alt="cashapp svg"/>
                    <img src={venmo} className='venmo-icon' alt="venmo svg"/>
                </div>

                <div className="flex-row social-icon-container">
                    <FontAwesomeIcon icon={faEnvelope} className='email-icon footer-icon' onClick={() => {window.open('mailto:laura.martinez.massage.yoga@gmail.com', '_blank');}}/>
                    <FontAwesomeIcon icon={faFacebook} className='facebook-icon footer-icon' onClick={() => {window.open('https://www.facebook.com/Laura.Martinez.Massage.Yoga', '_blank');}}/>
                    <FontAwesomeIcon icon={faInstagram} className='instagram-icon footer-icon' onClick={() => {window.open('https://www.instagram.com/laura.martinez.massage.yoga/', '_blank');}}/>
                    <FontAwesomeIcon icon={faLinkedin} className='linkedin-icon footer-icon' onClick={() => {window.open('https://www.linkedin.com/in/lauramartinezmassageyoga/', '_blank');}}/>
                </div>

            </div>
        );

    }

}

export default Footer;
import React, { useEffect } from 'react';
import useWindowDimensions from '../../components/getWindowDimensions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import './EventsTab.css'

function LocationSearchInput({ setLocation, location }) {

  const width = useWindowDimensions().width;

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('location-input'),
      { types: ['geocode'] }
    );
    autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place && place.address_components) {
        const addressComponents = place.address_components;
        let formattedLocation = place.name;

        // Extract and concatenate desired address components
        const city = addressComponents.find(
          component => component.types.includes('locality')
        );
        const state = addressComponents.find(
          component => component.types.includes('administrative_area_level_1')
        );
        const country = addressComponents.find(
          component => component.types.includes('country')
        );

        if (city && state && country) {
          formattedLocation += `, ${city.long_name}, ${state.short_name}, ${country.long_name}`;
        }

        // Set the location value
        setLocation(formattedLocation);
      }
    });
  }, [setLocation]);

  return (
    <div className="location-search-input">
      <input
        id="location-input"
        type="text"
        placeholder="Where is the event going to happen? *"
        onChange={event => setLocation(event.target.value)}
        className={`events-request-input ${(width < 750) ? 'events-request-input-resize' : ''}`}
        value={location}
      />
      <FontAwesomeIcon icon={faLocationDot} className='icon-x' />
    </div>
  );
}

export default LocationSearchInput;

import './AboutMe.css';
import React from 'react';
import laura_pic from '../../images/laura.jpeg';
import useWindowDimensions from '../../components/getWindowDimensions';
import RYT200 from '../../images/RYT200-logo.png';

function AboutMeText() {

    const width = useWindowDimensions().width;

    return(
        <div className={`flex-column ${(width > 1200) ? 'AboutMe-text-container' : 'AboutMe-text-container-resized'}`}>

            <div className='flex-row AboutMe-title-container'>
                <div>
                    <span className='recoleta medium center'>Laura Martinez</span>
                    <span className='recoleta small center'>Massage Therapist (LMT 14820)</span>
                    <span className='recoleta small center'>Yoga Instructor (RYT200)</span>
                </div>
                <img src={RYT200} className='RYT-logo' alt='RYT200 logo'/>
            </div>
            <br/>
            <span>I am originally from Mendoza, Argentina but I had been living in the USA since 2000. Being bilingual
                allows me to accommodate English and Spanish speakers. Compassion connects me with other beings and because
                of my personality I create an inviting, friendly and caring environment for everyone around me. My goal is to
                promote relaxation, wellness, and health through combining the principles of Massage and Yoga Therapies.</span>
            <br/>

            <span className='bold'>Professional Experience</span>
            <span>2022-Present: Owner, Massage Therapist and Yoga Instructor. Laura Martinez Therapeutic Massage & Yoga Winston Salem, NC.</span>
            <br/>
            <span>2017-2022: Massage Therapist. Neroli Spa. Mequon, WI.</span>
            <br/>
            <span>2019-2022 : Yoga Instructor. Rite Hite YMCA. Brown Deer, WI.</span>
            <br/>
            <span>2015-2016: Owner and Massage Therapist. Beautiful Touch. Greensboro, NC.</span>
            <br/>

            <span className='bold'>Education, Licensure, and Certification</span>
            <span>2023: First Aid, CPR, and AED Certified. American Safety Health Institute, Winston Salem, NC.</span>
            <br/>
            <span>2019: Certified Hatha Yoga Instructor (RYT200) IYT program. Inner Light Yoga Studios and School of Yoga, WI.</span>
            <br/>
            <span>2015: Licensed Massage Therapist (LMT14820). Kneaded Energy School of Massage, Greensboro, NC.</span>
            <br/>
            <span>2003: Certified Nursing Assistant (CNA). North Central Technical College, Wausau, WI.</span>
            <br/>

            <span className='bold'>Continuing  and Advance Education</span>
            <span>2023: "Thai Massage", Michael Sitzer (Prone and Double Leg Techniques)</span>
            <span className='six-spaces'>"Thai Massage", Michael Sitzer (Supine and Side Lying Techniques)</span>
            <span className='six-spaces'>"Ashi-Thai Massage", Brady Preyss (Combination of Ashiatsu and Thai Massage")</span>
            <br/>
            <span>2022: “Ashiatsu Massage”, Brady Preyss Deep Feet 2 (Advance Techniques)</span>
            <br/>
            <span>2021: "Orthopedic Massage", James Waslaski</span>
            <span className='five-spaces'>"Elite Sport Therapy", James Waslaski</span>
            <span className='five-spaces'>"Thai Massage", Michael Sitzer</span>
            <span className='five-spaces'>"Ashiatsu Massage", Deep Feet 1 Bar Therapy</span>
            <br/>
            <span>2020: "Ashiatsu Massage", Deep Feet 1 Bar Therapy</span>
            <br/>
            <span>2019: "Thai Massage", Chuck Duff</span>
            <br/>
            <span>2018: "Thai Massage", Chuck Duff</span>
            <span className='five-spaces'>"Cupping Therapy", Rita Woods</span>
            <span className='five-spaces'>"Shiatsu Massage", Seymour Koblin</span>
            <br/>
            <span>2017: "Isolated Muscle Stretching", Joshua Morton</span>
            <span className='five-spaces'>"Combating Stress in the Healthcare Workplace," Home CEU Connection</span>
            <span className='five-spaces'>"Myofascial Release Clinical Applications," Home CEU Connection</span>
        </div>
    );

}

function AboutMe() {

    const width = useWindowDimensions().width;

    return (
        <React.Fragment>

            <div className='AboutMe'>
                <div className={`${(width > 1200) ? 'AboutMe-info' : 'AboutMe-info-resize'}`}>

                    <div className={`${(width > 1200) ? 'AboutMe-image-container' : 'AboutMe-image-container-resized'}`}>
                        <div>
                            <img src={laura_pic} className='about-me-laura-pic' alt="laura nav bar pic"/>
                        </div>
                    </div>

                    <AboutMeText/>

                </div>
            </div>

        </React.Fragment>
    );
    

}

export default AboutMe;
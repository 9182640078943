import React from "react";
import IntakeForm from "./IntakeForm";
import { useSelector } from 'react-redux';
import { EnterDetailsStages } from "../../store/bookingSystemSlice";
import UserInfo from "./UserInfo";
import Terms from "./Terms";
import Summary from "./Summary";
import AreYouSure from "./AreYouSure";

function EnterDetails({firstName, lastName, phone, email, invalidEmail, invalidPhone, handleFirstNameInput, handleLastNameInput, handleEmailInput, handlePhoneInput}) {

    const { enterDetailsStage } = useSelector(state => state.bookingSystem);

    return(
        <div className='flex-column enter-details-container'>
            {(enterDetailsStage === EnterDetailsStages.UserInfo) ? (<UserInfo firstName={firstName} lastName={lastName} phone={phone} email={email} invalidEmail={invalidEmail} invalidPhone={invalidPhone}
                                                                              handleFirstNameInput={handleFirstNameInput} handleLastNameInput={handleLastNameInput} handleEmailInput={handleEmailInput} handlePhoneInput={handlePhoneInput}/>) : 
             (enterDetailsStage === EnterDetailsStages.IntakeForm) ? (<IntakeForm/>) : 
             (enterDetailsStage === EnterDetailsStages.AreYouSure) ? (<AreYouSure/>) : 
             (enterDetailsStage === EnterDetailsStages.Terms) ? (<Terms/>) : (<Summary/>)}
        </div>
    );


}

export default EnterDetails;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import './BookingSystem.css';
import useWindowDimensions from '../../components/getWindowDimensions';


function TitleBar({handleCloseClick}) {

    const width = useWindowDimensions().width;

    if(width < 445) {
        return (
        <div className='title-bar-container'>

            <div className='icon-x-container'>
                <FontAwesomeIcon icon={faCircleXmark} className='booking-system-icon-x' onClick={handleCloseClick}/>
            </div>

            <br/>

            <span className='bold center text-center'>Laura Martinez Therapeutic Massage & Yoga</span>

            {/* HORIZONTAL LINE */}
            <div style={{width: '100%'}}>
                <hr style={{width: '100%', border: '1px solid black'}}/>
            </div>

        </div>
        );
    }

    else {
        return (
            <div className='title-bar-container'>

                <div className='icon-x-container'>
                    <FontAwesomeIcon icon={faCircleXmark} className='booking-system-icon-x' onClick={handleCloseClick}/>
                </div>

                <span className='bold title-padding'>Laura Martinez Therapeutic Massage & Yoga</span>
                <br/>

                {/* HORIZONTAL LINE */}
                <div style={{width: '100%'}}>
                    <hr style={{width: '100%', border: '1px solid black'}}/>
                </div>

            </div>
        );
    }
}

export default TitleBar;
import React from "react";
import MultiOptionCheckBox from "../../components/MultiOptionCheckBox";
import { useSelector, useDispatch } from 'react-redux';
import { setTerms } from '../../store/bookingSystemSlice';


function Terms() {

    const dispatch = useDispatch();
    const { terms } = useSelector(state => state.bookingSystem);

    const handleTermsChange = (selectedOptions) => {
        const updatedTerms = selectedOptions.reduce((result, isSelected, index) => {
          result[Object.keys(terms)[index]] = isSelected;
          return result;
        }, {});
        dispatch(setTerms(updatedTerms));
    }

    return(
        <React.Fragment>

            <span className='bold medium'>Terms of Service</span>

            <span>Check the following to agree the terms for your services with me: <span style={{color: 'red'}}>*</span> </span>   
            <MultiOptionCheckBox options={Object.keys(terms)} selectedOptions={Object.values(terms)} setSelectedOptions={handleTermsChange}/>

            <br/>

            <span className="cancellation-text">
                I ask that you cancel your appointment 24 hours in advance. You will receive an email that will allow you to cancel if it's 24 hours in advance.
                If you need to cancel and it's under 24 hours until your appointment, please call Laura at (336) 552-0796.
            </span>

            <span className="cancellation-text">
                By creating this appointment, you acknowledge you will receive emails and texts from Laura.
            </span>

        </React.Fragment>
    );

}

export default Terms;
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFirstName, setLastName, setEmail, setPhone, setInvalidEmail, setInvalidPhone } from '../../store/bookingSystemSlice';


function UserInfo() {

    const dispatch = useDispatch();
    const { firstName, lastName, email, phone, invalidEmail, invalidPhone } = useSelector(state => state.bookingSystem);

    const handleFirstNameInput = (e) => {
        if(/^[a-zA-Z ]*$/.test(e.target.value)) {
            dispatch(setFirstName(e.target.value));
        }
    };

    const handleLastNameInput = (e) => {
        if(/^[a-zA-Z ]*$/.test(e.target.value)) {
            dispatch(setLastName(e.target.value));
        }
    };

    const handleEmailInput = (e) => {
        dispatch(setEmail(e.target.value));

        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value) && e.target.value.length > 0) {
            dispatch(setInvalidEmail(true));
        }
        else {
            dispatch(setInvalidEmail(false));
        }
    };

    const handlePhoneInput = (e) => {
        const input = e.target.value.replace(/\D/g, "").slice(0, 10);
        let formattedPhoneNumber = "";
    
        if (input.length > 0) {
            formattedPhoneNumber = `(${input.slice(0, 3)}`;
        }
        if (input.length > 3) {
            formattedPhoneNumber += `) ${input.slice(3, 6)}`;
        }
        if (input.length > 6) {
            formattedPhoneNumber += `-${input.slice(6, 10)}`;
        }

        if (input.length === 0 || input.length === 10) {
            dispatch(setInvalidPhone(false));
            } else {
            dispatch(setInvalidPhone(true));
            }
    
        dispatch(setPhone(formattedPhoneNumber));
    };

    return(
        <React.Fragment>

            <span className='bold medium'>Enter your details below</span>

            <div className="flex-row full-name-container">
                <input className='enter-details-input' type="text" spellCheck={false} placeholder='First name *' name='firstName' value={firstName} onInput={handleFirstNameInput}/>
                <input className='enter-details-input' type="text" spellCheck={false} placeholder='Last name *' name='lastName' value={lastName} onInput={handleLastNameInput}/>
            </div>

            <input className={`enter-details-input ${(invalidEmail) ? 'phone-not-complete' : ''}`} type="email" spellCheck={false} placeholder='Email *' value={email} onInput={handleEmailInput}/>

            <input className={`enter-details-input ${(invalidPhone) ? 'phone-not-complete' : ''}`} type="text" spellCheck={false} placeholder='Phone number *' value={phone} onInput={handlePhoneInput}/>

        </React.Fragment>
    );

}

export default UserInfo;
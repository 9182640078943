import React from 'react';
import './BookingSystem.css';
import moment from 'moment';
import moment_tz from 'moment-timezone';
import CalendarComponent from './Calendar';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedTime } from '../../store/bookingSystemSlice';
import { v4 as uuidv4 } from 'uuid';
import useWindowDimensions from '../../components/getWindowDimensions';

function SelectWithFreeSlots() {

  const width = useWindowDimensions().width;

  // redux states
  const dispatch = useDispatch();
  const { selectedDate, freeSlots, selectedTime } = useSelector(state => state.bookingSystem);

  // formatting function
  function formatDate(isoDate) {
    const date = moment_tz.tz(isoDate, "UTC").tz("America/New_York").format("MM-DD-YYYY");

    return(date);
  }

  // helper functions
  function roundTimeUp(time) {
    let roundedTime = moment(time, 'HH:mm');
    let remainder = roundedTime.minute() % 30;
    if (remainder !== 0) {
      roundedTime.add(30 - remainder, 'minutes');
    }
    return roundedTime.format('HH:mm');
  }

  function roundTimeDown(time) {
    let roundedTime = moment(time, 'HH:mm');
    let remainder = roundedTime.minute() % 30;
    roundedTime.subtract(remainder, 'minutes');
    return roundedTime.format('HH:mm');
  }

  function getIncrements(start, end) {
    let startTime = moment(roundTimeUp(start), 'HH:mm');
    let endTime = moment(roundTimeDown(end), 'HH:mm');
  
    let increments = [];
    while (startTime.isBefore(endTime)) {
      increments.push(startTime.format('h:mm A'));
      startTime.add(30, 'minutes');
    }
    increments.push(endTime.format('h:mm A'));
  
    return increments;
  }

  // create time buttons under calendar
  function TimeSlots() {

    // filter freeSlots so that it only includes data of the date selected
    let filteredFreeSlots = freeSlots.filter(obj => obj.date === formatDate(moment.unix(selectedDate/1000).toISOString()));

    // time buttons
    return (
      <React.Fragment>
        {filteredFreeSlots.map((slot) => (
          getIncrements(slot.start, slot.end).map((time) => (
            <div key={uuidv4()} className={`time-button ${selectedTime === time ? 'selected-time-button' : ''}`}
                  onClick={() => dispatch(setSelectedTime(time))}>
              <span>{time}</span>
            </div>
          ))
        ))}
      </React.Fragment>
    );

  }

  if (width < 915) {
    return (
      <div className='flex-column select-service-date-time-container'>

        <span className='bold medium'>Select your preferred date and time</span>

        <CalendarComponent/>

        <div className='flex-column availability-day-container'>
        <span className='bold availability-text center text-center'>{selectedDate ? `Available on ${moment.unix(selectedDate/1000).format("dddd, MMMM Do, YYYY")}` : ''}</span>

          <span className='availability-day-info-text center text-center'>You can schedule an appointment between 4 hours and 30 days ahead of time. Time zone: Eastern Time Zone (EST)</span>

          <div className='flex-row time-slots-container time-slots-container-resized'>
              <TimeSlots/>
          </div>

        </div>

      </div>
    );
  }

  else {
    return (
      <div className='flex-column select-service-date-time-container'>

        <span className='bold medium'>Select your preferred date and time</span>

        <CalendarComponent/>

        <div className='flex-column availability-day-container'>
          <span className='bold availability-text'>{selectedDate ? `Available on ${moment.unix(selectedDate/1000).format("dddd, MMMM Do, YYYY")}` : ''}</span>

          <span className='availability-day-info-text'>You can schedule an appointment between 4 hours and 30 days ahead of time. Time zone: Eastern Time Zone (EST)</span>

          <div className='flex-row time-slots-container'>
              <TimeSlots/>
          </div>

        </div>

      </div>
    );
  }
} 

function SelectDateAndTime() {

  const { freeSlots } = useSelector(state => state.bookingSystem);

  // if get request is not done yet
  if (freeSlots === null) {
    return (
      <div>
        Finding availabilities...
      </div>
    );
  }

  // if get request shows that no days are available
  else if (freeSlots.length === 0) {
    return (
      <div>
        There are no availabilites.
      </div>
    );
  }

  // if days are available
  else {
    return <SelectWithFreeSlots/>
  }

}

export default SelectDateAndTime;
import React from 'react';
import './StudioTab.css';
import useWindowDimensions from '../../components/getWindowDimensions';
import consultation_area from '../../images/consultation-area.webp';
import massage_table from '../../images/massage-table.webp';
import relaxation_area from '../../images/relaxation-area.webp';

function StudioInside() {
    const width = useWindowDimensions().width;

    if (width < 450) {
        return (
            <React.Fragment>
    
                <div className='studio-mandala-container flex-column'>
    
                    <div className='flex-column text-container'>
                        <span className='recoleta medium text-center center'>A perfect space for the moment that matters... Your moment</span>
                        <span className='text-center center'>The space has an independent entrance, private bathroom, adjustable ambient lighting 
                                                             level, adjustable room temperature and customized music.</span>
                    </div>
    
                    <div className='flex-column align-items-center instructions-container instructions-mobile'>
                        <div className='flex-column picture-inside-mobile-container subtitle-padding'>
    
                            <span className='recoleta medium text-center center'>Consultation Area</span>
                            <img src={consultation_area} className='studio-inside-sizing object-position-center-bottom' alt="consultation area"/>
                            <span className='text-center center'>Comfortable seating</span>
    
                        </div>
    
                        <div className='flex-column picture-inside-mobile-container'>
    
                            <span className='recoleta medium text-center center'>Oversized Massage Table</span>
                            <img src={massage_table} className='studio-inside-sizing' alt="massage table"/>
                            <span className='text-center center'>With adjustable face rest and table warmer</span>
                            
                        </div>
    
                        <div className='flex-column picture-inside-mobile-container'>
                            
                            <span className='recoleta medium text-center center'>Relaxation Area</span>
                            <img src={relaxation_area} className='studio-inside-sizing object-position-center-bottom' alt="relaxation area"/>
                            <span className='text-center center'>A relaxation moment for your companion during Couples Massage Back to Back</span>
    
                        </div>
                    </div>
    
                </div>
    
            </React.Fragment>
        );
    }

    else if (width < 1350) {
        return (
            <React.Fragment>
    
                <div className='studio-mandala-container flex-column'>
    
                    <div className='flex-column text-container'>
                        <span className='recoleta medium text-center center'>A perfect space for the moment that matters... Your moment</span>
                        <span className='text-center center'>The space has an independent entrance, private bathroom, adjustable ambient lighting 
                                                             level, adjustable room temperature and customized music.</span>
                    </div>
    
                    <div className='flex-column align-items-center instructions-container instructions-mobile'>
                        <div className='flex-column picture-inside-container'>
    
                            <span className='recoleta medium text-center center'>Consultation Area</span>
                            <img src={consultation_area} className='studio-inside-sizing object-position-center-bottom' alt="consultation area"/>
                            <span className='text-center center'>Comfortable seating</span>
    
                        </div>
    
                        <div className='flex-column picture-inside-container'>
    
                            <span className='recoleta medium text-center center'>Oversized Massage Table</span>
                            <img src={massage_table} className='studio-inside-sizing' alt="massage table"/>
                            <span className='text-center center'>With adjustable face rest and table warmer</span>
                            
                        </div>
    
                        <div className='flex-column picture-inside-container'>
                            
                            <span className='recoleta medium text-center center'>Relaxation Area</span>
                            <img src={relaxation_area} className='studio-inside-sizing object-position-center-bottom' alt="relaxation area"/>
                            <span className='text-center center'>A relaxation moment for your companion during Couples Massage Back to Back</span>
    
                        </div>
                    </div>
    
                </div>
    
            </React.Fragment>
        );
    }

    else {

        return (
            <React.Fragment>

                <div className='studio-mandala-container flex-column'>

                    <div className='flex-column text-container'>
                        <span className='recoleta medium text-center center'>A perfect space for the moment that matters... Your moment</span>
                        <span className='text-center center'>The space has an independent entrance, private bathroom, adjustable ambient lighting 
                                                             level, adjustable room temperature and customized music.</span>
                    </div>

                    <div className='flex-row instructions-container'>
                        <div className='flex-column picture-inside-container'>

                            <span className='recoleta medium text-center center'>Consultation Area</span>
                            <img src={consultation_area} className='studio-inside-sizing object-position-center-bottom' alt="consultation area"/>
                            <span className='text-center center'>Comfortable seating</span>

                        </div>

                        <div className='flex-column picture-inside-container'>

                            <span className='recoleta medium text-center center'>Oversized Massage Table</span>
                            <img src={massage_table} className='studio-inside-sizing' alt="massage table"/>
                            <span className='text-center center'>With adjustable face rest and table warmer</span>
                            
                        </div>

                        <div className='flex-column picture-inside-container'>
                            
                            <span className='recoleta medium text-center center'>Relaxation Area</span>
                            <img src={relaxation_area} className='studio-inside-sizing object-position-center-bottom' alt="relaxation area"/>
                            <span className='text-center center'>A relaxation moment for your companion during Couples Massage Back to Back</span>

                        </div>
                    </div>

                </div>

            </React.Fragment>
        );
    }

}

export default StudioInside
import React from "react";
import "./FAQ.css";
import useWindowDimensions from "../../components/getWindowDimensions";

function FAQ() {
  const width = useWindowDimensions().width;

  if (width < 1250) {
    return (
      <React.Fragment>
        <div className="FAQ-mandala-container FAQ-mandala-container-resized flex-column">
          <span className="recoleta medium">About your visit and more...</span>

          <div className="flex-column FAQ-text-container-resized">
            <span className="bold">How do I schedule?</span>
            <span>
              Press the "Book" button on the right top corner of this page and
              just follow the require steps for booking. You can also call or
              text me at (336)552-0796 and I'll do the reservation for you.
            </span>
          </div>

          <div className="flex-column FAQ-text-container-resized">
            <span className="bold">How do I cancel or reschedule?</span>
            <span>
              I ask for a 24hrs cancellation notice. There is a link in the
              confirmation email of your reservation that says "cancel or
              reschedule" you can follow that link or call or text me ASAP at
              (336)552-0796.
            </span>
          </div>

          <div className="flex-column FAQ-text-container-resized">
            <span className="bold">How do I get there?</span>
            <span>
              Press "The Studio" button on the right top corner of this page to
              see detailed information. Also there are instruction in the first
              confirmation email of your reservation.
            </span>
          </div>

          <div className="flex-column FAQ-text-container-resized">
            <span className="bold">Is there parking available?</span>
            <span>Yes, there is always a saved parking space for you.</span>
          </div>

          <div className="flex-column FAQ-text-container-resized">
            <span className="bold">What time should I arrive?</span>
            <span>
              You should arrive right at the time of your reservation. Please
              notice that there is no waiting room inside the studio. If you
              happen to be here a couple of minutes earlier, you are welcome to
              seat in the bench or table outside the studio.
            </span>
          </div>

          <div
            className="flex-column FAQ-text-container-resized"
            style={{ wordWrap: "break-word" }}
          >
            <span className="bold text-break">
              I am pregnant/older/younger/underweight/overweight/out of shape/in
              great shape... can you see me anyways?
            </span>
            <span>
              Absolutely. My services are customized for everyone needs.
            </span>
          </div>

          <div className="flex-column FAQ-text-container-resized">
            <span className="bold">
              What about couples massage for friends, partners and family
              members?
            </span>
            <span>
              There is a relaxation area available for couples taking back to
              back massages with me. Read more details by pressing the sub tab
              "Couples Massage" under "Massage Therapy" above.
            </span>
          </div>

          <div className="flex-column FAQ-text-container-resized">
            <span className="bold">How do I prepare for the massage?</span>
            <span>
              If you can, hydrate the tissues of your body by drinking water and
              taking a shower or bath before the service. That help the massage
              being more effective by starting the process in stimulating the
              lymphatic and circulatory systems, making the tissues more
              malleable and calming the mind.
            </span>
          </div>

          <div className="flex-column FAQ-text-container-resized">
            <span className="bold">How are the times of the session?</span>
            <span>
              Your session time start right at the time of your reservation with
              your arrival at the Studio. I dedicate 10-15 minutes to welcome
              you, consultation, undressing/dressing, feedback and check out.
              Leaving us with the rest of the time for hands-on body work.
            </span>
          </div>

          <div className="flex-column FAQ-text-container-resized">
            <span className="bold">
              How often do I need to receive a massage?
            </span>
            <span>
              All depends on each individual and specific condition, as every
              one is unique.
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="FAQ-mandala-container flex-column">
          <span className="recoleta medium">About your visit and more...</span>

          <div className="flex-column FAQ-text-container">
            <span className="bold">How do I schedule?</span>
            <span>
              Press the "Book" button on the right top corner of this page and
              just follow the require steps for booking. You can also call or
              text me at (336)552-0796 and I'll do the reservation for you.
            </span>
          </div>

          <div className="flex-column FAQ-text-container">
            <span className="bold">How do I cancel or reschedule?</span>
            <span>
              I ask for a 24hrs cancelation notice. There is a link in the
              confirmation email of your reservation that says "cancel or
              reschedule" you can follow that link or call or text me ASAP at
              (336)552-0796.
            </span>
          </div>

          <div className="flex-column FAQ-text-container">
            <span className="bold">How do I get there?</span>
            <span>
              Press "The Studio" button on the right top corner of this page to
              see detailed information. Also there are instruction in the first
              confirmation email of your reservation.
            </span>
          </div>

          <div className="flex-column FAQ-text-container">
            <span className="bold">Is there parking available?</span>
            <span>Yes, there is always a saved parking space for you.</span>
          </div>

          <div className="flex-column FAQ-text-container">
            <span className="bold">What time should I arrive?</span>
            <span>
              You should arrive right at the time of your reservation. Please
              notice that there is no waiting room inside the studio. If you
              happen to be here a couple of minutes earlier, you are welcome to
              seat in the bench or table outside the studio.
            </span>
          </div>

          <div className="flex-column FAQ-text-container">
            <span className="bold">
              I am pregnant/older/younger/underweight/overweight/out of shape/in
              great shape... can you see me anyways?
            </span>
            <span>
              Absolutely. My services are customized for everyone needs.
            </span>
          </div>

          <div className="flex-column FAQ-text-container">
            <span className="bold">
              What about couples massage for friends, partners and family
              members?
            </span>
            <span>
              There is a relaxation area available for couples taking back to
              back massages with me. Read more details by pressing the sub tab
              "Couples Massage" under "Massage Therapy" above.
            </span>
          </div>

          <div className="flex-column FAQ-text-container">
            <span className="bold">How do I prepare for the massage?</span>
            <span>
              If you can, hydrate the tissues of your body by drinking water and
              taking a shower or bath before the service. That help the massage
              being more effective by starting the process in stimulating the
              lymphatic and circulatory systems, making the tissues more
              malleable and calming the mind.
            </span>
          </div>

          <div className="flex-column FAQ-text-container">
            <span className="bold">How are the times of the session?</span>
            <span>
              Your session time start right at the time of your reservation with
              your arrival at the Studio. I dedicate 10-15 minutes to welcome
              you, consultation, undressing/dressing, feedback and check out.
              Leaving us with the rest of the time for hands-on body work.
            </span>
          </div>

          <div className="flex-column FAQ-text-container">
            <span className="bold">
              How often do I need to receive a massage?
            </span>
            <span>
              All depends on each individual and specific condition, as every
              one is unique.
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FAQ;

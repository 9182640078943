import React from "react";
import "./MassageTherapyHome.css";
import tingsha_bells from "../../images/tingsha-bells.jpeg";
import elbow_massage from "../../images/elbow-massage.jpeg";
import rocks_massage from "../../images/rocks-massage.jpeg";
import suction_cups from "../../images/suction-cups.jpeg";
import traditional_massage from "../../images/traditional-massage.jpeg";
import ashiatsu_massage from "../../images/ashiatsu-massage.jpeg";
import useWindowDimensions from "../../components/getWindowDimensions";
import { Tabs } from "../../App";

function MassageTherapyHome({ setCurrentTab }) {
  const width = useWindowDimensions().width;

  function PictureContainer() {
    if (width < 490) {
      return (
        <div className="MTH-pictures-container">
          <div>
            <span className="recoleta medium text-center">
              Customized Massage Therapy Session
            </span>
            <br />
            <span className="small text-center">
              Your session may include all or some of the following:
            </span>
            <br />
          </div>

          <div className="pictures-container flex-column center">
            <div className="text-col-container-mobile">
              <div className="col-container">
                <img
                  className="img-sizing"
                  src={tingsha_bells}
                  alt="tingsha bells"
                ></img>
              </div>
              <span className="text-center">
                The healing sound of the TINGSHA BELLS calms the nervous system
                relieving stress and anxiety.
              </span>
            </div>

            <div className="text-col-container-mobile">
              <div className="col-container">
                <img className="img-sizing" src={elbow_massage} alt=""></img>
              </div>
              <span className="text-center">
                HOT TOWELS hydrates the skin improving product absorption and
                leaving the skin pliable and workable.
              </span>
            </div>

            <div className="text-col-container-mobile">
              <div className="col-container">
                <img
                  className="img-sizing"
                  style={{ objectFit: "fill" }}
                  src={rocks_massage}
                  alt=""
                ></img>
              </div>
              <span className="text-center">
                HOT STONES warm up and soften the tissues and allows for
                localized work.
              </span>
            </div>

            <div className="text-col-container-mobile">
              <div className="col-container">
                <img className="img-sizing" src={suction_cups} alt=""></img>
              </div>
              <span className="text-center">
                SUCTION CUPS soften adhesions and stimulate the circulatory and
                lymphatic systems.
              </span>
            </div>

            <div className="text-col-container-mobile">
              <div className="col-container">
                <img
                  className="img-sizing"
                  style={{ objectPosition: "bottom" }}
                  src={traditional_massage}
                  alt=""
                ></img>
              </div>
              <span className="text-center">TRADITIONAL MASSAGE</span>
            </div>

            <div className="text-col-container-mobile">
              <div className="col-container">
                <img className="img-sizing" src={ashiatsu_massage} alt=""></img>
              </div>
              <span className="text-center">ASHIATSU MASSAGE</span>
            </div>
          </div>
        </div>
      );
    } else if (width < 1020) {
      return (
        <div className="MTH-pictures-container">
          <div>
            <span className="recoleta medium center">
              Customized Massage Therapy Session
            </span>
            <br />
            <span className="small center">
              Your session may include all or some of the following:
            </span>
            <br />
          </div>

          <div className="pictures-container flex-column center">
            <div className="text-col-container">
              <div className="col-container">
                <img
                  className="img-sizing"
                  src={tingsha_bells}
                  alt="tingsha bells"
                ></img>
              </div>
              <span className="text-center">
                The healing sound of the TINGSHA BELLS calms the nervous system
                relieving stress and anxiety.
              </span>
            </div>

            <div className="text-col-container">
              <div className="col-container">
                <img className="img-sizing" src={elbow_massage} alt=""></img>
              </div>
              <span className="text-center">
                HOT TOWELS hydrates the skin improving product absorption and
                leaving the skin pliable and workable.
              </span>
            </div>

            <div className="text-col-container">
              <div className="col-container">
                <img className="img-sizing" src={rocks_massage} alt=""></img>
              </div>
              <span className="text-center">
                HOT STONES warm up and soften the tissues and allows for
                localized work.
              </span>
            </div>

            <div className="text-col-container">
              <div className="col-container">
                <img className="img-sizing" src={suction_cups} alt=""></img>
              </div>
              <span className="text-center">
                SUCTION CUPS soften adhesions and stimulate the circulatory and
                lymphatic systems.
              </span>
            </div>

            <div className="text-col-container">
              <div className="col-container">
                <img
                  className="img-sizing"
                  style={{ objectPosition: "bottom" }}
                  src={traditional_massage}
                  alt=""
                ></img>
              </div>
              <span className="text-center">TRADITIONAL MASSAGE</span>
            </div>

            <div className="text-col-container">
              <div className="col-container">
                <img className="img-sizing" src={ashiatsu_massage} alt=""></img>
              </div>
              <span className="text-center">ASHIATSU MASSAGE</span>
            </div>
          </div>
        </div>
      );
    } else if (width < 1440) {
      return (
        <div className="MTH-pictures-container">
          <div>
            <span className="recoleta medium center">
              Customized Massage Therapy Session
            </span>
            <br />
            <span className="small center">
              Your session may include all or some of the following:
            </span>
            <br />
          </div>

          <div className="pictures-container flex-column">
            <div className="row-container flex-row">
              <div className="text-col-container">
                <div className="col-container">
                  <img
                    className="img-sizing"
                    src={tingsha_bells}
                    alt="tingsha bells"
                  ></img>
                </div>
                <span className="text-center">
                  The healing sound of the TINGSHA BELLS calms the nervous
                  system relieving stress and anxiety.
                </span>
              </div>

              <div className="text-col-container">
                <div className="col-container">
                  <img className="img-sizing" src={elbow_massage} alt=""></img>
                </div>
                <span className="text-center">
                  HOT TOWELS hydrates the skin improving product absorption and
                  leaving the skin pliable and workable.
                </span>
              </div>
            </div>

            <div className="row-container flex-row">
              <div className="text-col-container">
                <div className="col-container">
                  <img className="img-sizing" src={rocks_massage} alt=""></img>
                </div>
                <span className="text-center">
                  HOT STONES warm up and soften the tissues and allows for
                  localized work.
                </span>
              </div>

              <div className="text-col-container">
                <div className="col-container">
                  <img className="img-sizing" src={suction_cups} alt=""></img>
                </div>
                <span className="text-center">
                  SUCTION CUPS soften adhesions and stimulate the circulatory
                  and lymphatic systems.
                </span>
              </div>
            </div>

            <div className="row-container flex-row">
              <div className="text-col-container">
                <div className="col-container">
                  <img
                    className="img-sizing"
                    style={{ objectPosition: "bottom" }}
                    src={traditional_massage}
                    alt=""
                  ></img>
                </div>
                <span className="text-center">TRADITIONAL MASSAGE</span>
              </div>

              <div className="text-col-container">
                <div className="col-container">
                  <img
                    className="img-sizing"
                    src={ashiatsu_massage}
                    alt=""
                  ></img>
                </div>
                <span className="text-center">ASHIATSU MASSAGE</span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="MTH-pictures-container">
          <div>
            <span className="recoleta medium center">
              Customized Massage Therapy Session
            </span>
            <br />
            <span className="small center">
              Your session may include all or some of the following:
            </span>
            <br />
          </div>

          <div className="pictures-container flex-column">
            <div className="row-container flex-row">
              <div className="text-col-container">
                <div className="col-container">
                  <img
                    className="img-sizing"
                    src={tingsha_bells}
                    alt="tingsha bells"
                  ></img>
                </div>
                <span className="text-center">
                  The healing sound of the TINGSHA BELLS calms the nervous
                  system relieving stress and anxiety.
                </span>
              </div>

              <div className="text-col-container">
                <div className="col-container">
                  <img className="img-sizing" src={elbow_massage} alt=""></img>
                </div>
                <span className="text-center">
                  HOT TOWELS hydrates the skin improving product absorption and
                  leaving the skin pliable and workable.
                </span>
              </div>

              <div className="text-col-container">
                <div className="col-container">
                  <img className="img-sizing" src={rocks_massage} alt=""></img>
                </div>
                <span className="text-center">
                  HOT STONES warm up and soften the tissues and allows for
                  localized work.
                </span>
              </div>
            </div>

            <div className="row-container flex-row">
              <div className="text-col-container">
                <div className="col-container">
                  <img className="img-sizing" src={suction_cups} alt=""></img>
                </div>
                <span className="text-center">
                  SUCTION CUPS soften adhesions and stimulate the circulatory
                  and lymphatic systems.
                </span>
              </div>

              <div className="text-col-container">
                <div className="col-container">
                  <img
                    className="img-sizing"
                    style={{ objectPosition: "bottom" }}
                    src={traditional_massage}
                    alt=""
                  ></img>
                </div>
                <span className="text-center">TRADITIONAL MASSAGE</span>
              </div>

              <div className="text-col-container">
                <div className="col-container">
                  <img
                    className="img-sizing"
                    src={ashiatsu_massage}
                    alt=""
                  ></img>
                </div>
                <span className="text-center">ASHIATSU MASSAGE</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <div className="MTH-container">
        <div className="MTH-mandala-text-container flex-column">
          <span className="recoleta medium center text-center">
            Each client is unique and deserves a customized service every
            time...
          </span>
          <br />
          <span className="center text-center">
            We'll begin with a brief consultation to learn about your goals for
            the session. Getting to know your concerns, expectations and
            preferences is very important for me to being able to design your
            customized session.
          </span>
          <br />
          <span className="center text-center">
            During the session you will hear the healing sound of the tingsha
            bells twice. I use them to indicate the beginning and end of the
            service.
          </span>
          <br />
          <span className="center text-center">
            I begin the hands-on work by warming up the tissues with hot towels,
            hot stones and gliding suction cups. Then I apply a variety of
            modalities, techniques and pressures, including Traditional massage
            (using my hands) and Ashiatsu massage (using my feet).
          </span>
          <br />
          <span className="center text-center">
            The length of the service is well planned so it will allow time for
            a full body massage with attention to your specific areas of
            concern.
          </span>
          <br />
          <span className="center text-center">
            The results are impressive and remarkable because of the combination
            of amazing and unique techniques that are applied in a personalized
            "wholesome" manner that aims to relieve muscle & energetic tension,
            general pain and induces deep relaxation of the mind and body.
          </span>
          <br />
          <br />
          <div className="flex-row FAQ-container">
            <div
              className="FAQ-Button"
              onClick={() => {
                setCurrentTab(Tabs.FAQ);
              }}
            >
              <span className="small">FAQ</span>
            </div>
          </div>
        </div>
      </div>

      <PictureContainer />
    </React.Fragment>
  );
}

export default MassageTherapyHome;

import React from "react";
import YouTube from "react-youtube";
import useWindowDimensions from './getWindowDimensions';

function YoutubeVideo() {
    const width = useWindowDimensions().width;

    const opts = {
        height: `${(width < 650) ? '195' : '390'}`,
        width: '100%',
    };

    return (
        <div style={{width: `${(width < 650) ? '320px' : '640px'}`}}>
        <YouTube videoId="WbCRKN40fBY" opts={opts} />
        </div>
    );
}

export default YoutubeVideo;
  import React, { useState } from 'react';
  import {slide as Menu} from 'react-burger-menu';
  import './BurgerMenu.css';
  import {Tabs} from '../../App';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

  function BurgerMenu({setCurrentTab, handleBookingSystemOpenClick, isOpen, handleIsOpen, closeSideBar}) {

    const [massageTherapyTabClicked, setMassageTherapyTabClicked] = useState(false);
    const [yogaTabClicked, setYogaTabClicked] = useState(false);
    const [studioTabClicked, setStudioTabClicked] = useState(false);

    return (
      <Menu 
      right 
      isOpen={isOpen}
      onOpen={handleIsOpen}
      onClose={handleIsOpen}
      >

        <div style={{paddingBottom:'20px'}}>

          <div className='bm-tab' onClick={() => {
            setCurrentTab(Tabs.Home);
            closeSideBar();
          }}
          > Home</div>

          <div className='bm-tab' onClick={() => {
            setCurrentTab(Tabs.AboutMe);
            closeSideBar();
          }}>About Me</div>

          <div className='flex-column'>

            <div style={{position: 'relative'}} onClick={() => setMassageTherapyTabClicked(!massageTherapyTabClicked)}>
              <div className='bm-tab' style={{borderTop: 'none'}}>Massage Therapy</div>

              {(massageTherapyTabClicked) ? <FontAwesomeIcon icon={faChevronUp} className='icon-arrow'/> : <FontAwesomeIcon icon={faChevronDown} className='icon-arrow'/>}
            </div>

            <div className={`bm-subtab-wrapper ${massageTherapyTabClicked ? 'open' : ''}`}> 
              <div className='bm-subtab' onClick={() => {
                setCurrentTab(Tabs.MassageTherapy_Home);
                closeSideBar();
              }}>Home</div>
              <div className='bm-subtab' onClick={() => {
                setCurrentTab(Tabs.MassageTherapy_Trad);
                closeSideBar();
              }}>Traditional Massage</div>
              <div className='bm-subtab' onClick={() => {
                setCurrentTab(Tabs.MassageTherapy_Ash);
                closeSideBar();
              }}>Ashiatsu Massage</div>
              <div className='bm-subtab' onClick={() => {
                setCurrentTab(Tabs.MassageTherapy_Thai);
                closeSideBar();
              }}>Thai Massage</div>
              <div className='bm-subtab' onClick={() => {
                setCurrentTab(Tabs.MassageTherapy_Coup);
                closeSideBar();
              }}>Couples Massage</div>
            </div>
            
          </div>

          <div className='bm-tab' onClick={() => {
            setCurrentTab(Tabs.Yogassage);
            closeSideBar();
          }}>Yogassage Therapy</div>

          <div className='flex-column'>
            
            <div style={{position: 'relative'}} onClick={() => setYogaTabClicked(!yogaTabClicked)}>
              <div className='bm-tab' style={{borderTop: 'none'}}>Yoga Classes</div>

              {(yogaTabClicked) ? <FontAwesomeIcon icon={faChevronUp} className='icon-arrow'/> : <FontAwesomeIcon icon={faChevronDown} className='icon-arrow'/>}
            </div>

            <div className={`bm-subtab-wrapper ${yogaTabClicked ? 'open' : ''}`}> 
              <div className='bm-subtab' onClick={() => {
                setCurrentTab(Tabs.Yoga_Home);
                closeSideBar();
              }}>Home</div>
              {/* <div className='bm-subtab' onClick={() => setCurrentTab(Tabs.Yoga_Private)}>Private Yoga</div> */}
              <div className='bm-subtab' onClick={() => {
                setCurrentTab(Tabs.Yoga_Group);
                closeSideBar();
              }}>Group Classes</div>
            </div>
            
          </div>

          <div className='bm-tab' onClick={() => {
            setCurrentTab(Tabs.Events_Request);
            closeSideBar();
          }}>Events</div>

          <div className='flex-column'>
            
            <div style={{position: 'relative'}} onClick={() => setStudioTabClicked(!studioTabClicked)}>
              <div className='bm-tab' style={{borderTop: 'none'}}>The Studio</div>

              {(studioTabClicked) ? <FontAwesomeIcon icon={faChevronUp} className='icon-arrow'/> : <FontAwesomeIcon icon={faChevronDown} className='icon-arrow'/>}
            </div>

            <div className={`bm-subtab-wrapper ${studioTabClicked ? 'open' : ''}`}> 
              <div className='bm-subtab' onClick={() => {
                setCurrentTab(Tabs.Studio_Outside);
                closeSideBar();
              }}>Outside and Instructions</div>
              <div className='bm-subtab' onClick={() => {
                setCurrentTab(Tabs.Studio_Inside);
                closeSideBar();
              }}>Inside Space</div>
            </div>
            
          </div>

          <div className='bm-tab' onClick={() => {
            setCurrentTab(Tabs.FAQ);
            closeSideBar();
          }}>FAQ</div>

          <div className='bm-tab' onClick={() => {
            window.open('https://squareup.com/gift/MLTWWBJST3W2A/order');
            closeSideBar();
          }}>Gift Cards</div>

          <div className='book-container'>
            <div className='book' onClick={() => {
              handleBookingSystemOpenClick();
              closeSideBar();
            }}>
              <span>Book</span>
            </div>
          </div>

        </div>

      </Menu>
    );
  }

  export default BurgerMenu;
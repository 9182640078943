import React, { useState, useRef, useEffect } from 'react';
import './BookingSystem.css';
import TitleBar from './TitleBar';
import SelectService from './SelectService';
import SelectDateAndTime from './SelectDateAndTime';
import ContinueBar from './ContinueBar';
import { useSelector, useDispatch } from 'react-redux';
import { EnterDetailsStages, getFreeSlotsAsync, setFreeSlots, setSelectedTime, setEnterDetailsStage,
         setIntakeFormExists, setSelectedService, deleteAppointmentsAsync, setDeleteAppointmentsRun } from '../../store/bookingSystemSlice';
import EnterDetails from './EnterDetails';
import useWindowDimensions from '../../components/getWindowDimensions';
import useWindowHeight from '../../components/useWindowHeight';

export const BookingStates = {
    SelectService: 1,
    SelectDateAndTime: 2,
    EnterDetails: 3
}

function BookingState ({bookingState}) {

    const dispatch = useDispatch();
    const { selectedService, yogassageType, deleteAppointmentsRun } = useSelector(state => state.bookingSystem);


    switch(bookingState) {

        case BookingStates.SelectService:
            return <SelectService/>;

        case BookingStates.SelectDateAndTime:
            if (!deleteAppointmentsRun) {
                dispatch(deleteAppointmentsAsync())
                  .then(() => {
                    dispatch(getFreeSlotsAsync(selectedService, yogassageType));
                  })
                  .catch((error) => {
                    console.error(error);
                  });
            } 
            return <SelectDateAndTime/>;

        case BookingStates.EnterDetails:
            return <EnterDetails/>;

        default:
            return <SelectService/>;
    }
}

function BookingSystem({handleCloseClick}) {

    const width = useWindowDimensions().width;

    const height = useWindowHeight().height;

    // what step the user is currently on
    const [bookingState, setBookingState] = useState(BookingStates.SelectService);

    // redux to store other states
    const dispatch = useDispatch();
    const { enterDetailsStage } = useSelector(state => state.bookingSystem);

    const bookingSystemBodyRef = useRef(null);

    // scrolls to the top whenever the stages change
    useEffect(() => {
      bookingSystemBodyRef.current.scrollTop = 0;
    }, [bookingState, enterDetailsStage]);

    const handleContinueClick = () => {
        if(bookingState === BookingStates.SelectService) {
            setBookingState(BookingStates.SelectDateAndTime);
        }
        else if(bookingState === BookingStates.SelectDateAndTime) {
            setBookingState(BookingStates.EnterDetails);
        }
        else if(bookingState === BookingStates.EnterDetails && enterDetailsStage === EnterDetailsStages.UserInfo) {
            dispatch(setEnterDetailsStage(EnterDetailsStages.IntakeForm));
        }
        else if(bookingState === BookingStates.EnterDetails && enterDetailsStage === EnterDetailsStages.IntakeForm) {
            dispatch(setEnterDetailsStage(EnterDetailsStages.Terms));
        }
        else if(bookingState === BookingStates.EnterDetails && enterDetailsStage === EnterDetailsStages.Terms) {
            dispatch(setEnterDetailsStage(EnterDetailsStages.Summary));
        }
    }

    const handleBackClick = () => {
        if(bookingState === BookingStates.SelectDateAndTime) {
            dispatch(setSelectedService(null));
            dispatch(setSelectedTime(null));
            dispatch(setFreeSlots(null));
            dispatch(setDeleteAppointmentsRun(false));
            setBookingState(BookingStates.SelectService);
        }
        else if(bookingState === BookingStates.EnterDetails && enterDetailsStage === EnterDetailsStages.UserInfo) {
            // reset
            dispatch(setSelectedTime(null));
            dispatch(setFreeSlots(null));
            dispatch(setDeleteAppointmentsRun(false));
            setBookingState(BookingStates.SelectDateAndTime);
        }
        else if(bookingState === BookingStates.EnterDetails && enterDetailsStage === EnterDetailsStages.IntakeForm) {
            dispatch(setIntakeFormExists(null));
            dispatch(setEnterDetailsStage(EnterDetailsStages.UserInfo));
        }
        else if(bookingState === BookingStates.EnterDetails && enterDetailsStage === EnterDetailsStages.Terms) {
            dispatch(setEnterDetailsStage(EnterDetailsStages.IntakeForm));
        }
    }

    if (width < 915) {
        return (
            <div className="modal-overlay">
                <div className="modal-content modal-content-resized">
                    <div className='flex-column'>

                        <TitleBar handleCloseClick={handleCloseClick}/>

                        <div className={`booking-system-body-container ${(height < 450) ? 'horizontal-view-3' : (height < 550) ? 'horizontal-view-2' : (height < 650) ? 'horizontal-view-1' : ''}`} ref={bookingSystemBodyRef}>
                            <div className='flex-row booking-system-body-container-inner booking-system-body-container-inner-resized'>

                                <BookingState bookingState={bookingState}/>

                            </div>
                        </div>

                        <ContinueBar handleContinueClick={handleContinueClick} handleBackClick={handleBackClick} bookingState={bookingState}/>

                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <div className='flex-column'>

                        <TitleBar handleCloseClick={handleCloseClick}/>

                        <div className={`booking-system-body-container ${(height < 450) ? 'horizontal-view-3' : (height < 550) ? 'horizontal-view-2' : (height < 650) ? 'horizontal-view-1' : ''}`} ref={bookingSystemBodyRef}>
                            <div className='flex-row booking-system-body-container-inner'>

                                <div className='flex-column steps-container'>
                                    <span className={`steps-text ${(bookingState === BookingStates.SelectService) ? 'current-step' : ''}`}>Select service</span>
                                    <span className={`steps-text ${(bookingState === BookingStates.SelectDateAndTime) ? 'current-step' : ''}`}>Select date and time</span>
                                    <span className={`steps-text ${(bookingState === BookingStates.EnterDetails) ? 'current-step' : ''}`}>Enter your details</span>
                                </div>

                                <BookingState bookingState={bookingState}/>

                            </div>
                        </div>

                        <ContinueBar handleContinueClick={handleContinueClick} handleBackClick={handleBackClick} bookingState={bookingState} handleCloseClick={handleCloseClick}/>

                    </div>
                </div>
            </div>
        );
    }

}

export default BookingSystem;
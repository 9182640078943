import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedService, setMoreInfoClicked, setYogassageType } from '../../store/bookingSystemSlice';
import { Appointments } from '../../store/bookingSystemSlice';
import useWindowDimensions from '../../components/getWindowDimensions';

function SelectServiceCheckbox({service, price, time, info, options, index}) {

    const width = useWindowDimensions().width;

    // redux states
    const dispatch = useDispatch();
    const { selectedService, moreInfoClicked, yogassageType } = useSelector(state => state.bookingSystem);

    let option = service === selectedService; // checks the correct box

    const handleMoreInfoClicked = (event) => {
        dispatch(setMoreInfoClicked({ index, value: !moreInfoClicked[index] }));
        event.stopPropagation();
    }

    const handleOptionChange = () => {
        if(option) {
            dispatch(setSelectedService(null));
        }
        else {
            dispatch(setSelectedService(service));
        }
    }

    const handleSelectChange = (event) => {
        dispatch(setYogassageType(event.target.value));
        dispatch(setSelectedService("Yogassage Therapy"));
        event.stopPropagation();
    }

    return (
        <div className={`flex-column select-service-checkbox-container ${(option) ? 'select-service-checkbox-container-checked' : ''}`} onClick={handleOptionChange}>

            <div className='flex-row select-service-title-container'>
                <span className='bold'>{service}</span>
                <input 
                    type="checkbox" 
                    id={service}
                    name={service}
                    checked={option}
                    onChange={handleOptionChange}
                />
            </div>

            {(width < 385) ? 

            (<div className='flex-column appointment-info-container-resized'>
                {(service === "Yogassage Therapy") ? <span>5 options</span> : <span>{price} - {time}</span>}
                <span className='bold more-info-button' onClick={handleMoreInfoClicked}>More info</span>
            </div>) : 

            (<div className='flex-row'>
                <span>{(service === "Yogassage Therapy") ? <span>5 options</span> : <span>{price} - {time}</span>} - <span className='bold' onClick={handleMoreInfoClicked}>More info</span></span>
            </div>)}

            {(moreInfoClicked[index]) ? (<span className='more-info-text'>{info}</span>) : (<></>)}

            {(selectedService === "Yogassage Therapy" && service === "Yogassage Therapy") ? 
            (
            <select onClick={(event) => event.stopPropagation()} onChange={handleSelectChange} className='dropdown-input' value={yogassageType}>
                {options.map((option, index) => (
                    <option key={index} value={option.option}>
                        {`${option.price} - ${option.time} - ${option.option}`}
                    </option>
                ))}
            </select>
            ) 
            : (<></>)}

        </div>
    );

}

function SelectService() {

    return (
        <div className='flex-column select-service-container'>

            <span className='bold medium'>Select service</span>

            {/* checkbox for each appointment type */}
            {Appointments.map((AppointmentType, index) => (
                <SelectServiceCheckbox 
                    key={AppointmentType.type}
                    service={AppointmentType.type}
                    price={AppointmentType.price}
                    time={AppointmentType.time}
                    info={AppointmentType.info}
                    options={AppointmentType.options}
                    index={index}
                />
            ))}

        </div>
    );

}

export default SelectService;
import React, {useState} from 'react';
import './EventsTab.css';
import useWindowDimensions from '../../components/getWindowDimensions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimeInput from './TimeInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCalendar } from '@fortawesome/free-solid-svg-icons';
import MultiOptionCheckBox from '../../components/MultiOptionCheckBox';
import LocationSearchInput from './LocationSearchInput';
import axios from 'axios';

function EventsRequest() {

    const width = useWindowDimensions().width;

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [guests, setGuests] = useState("");
    const [selectedDate, setSelectedDate] = useState();
    const [time, setTime] = useState("");
    const [description, setDescription] = useState("");
    const [services, setServices] = useState({"Yoga Class": false, "Massage Service": false});
    const [location, setLocation] = useState('');

    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidPhone, setInvalidPhone] = useState(false);

    const handleNameInput = (e) => {
        if(/^[a-zA-Z ]*$/.test(e.target.value)) {
            setName(e.target.value);
        }
    };

    const handleEmailInput = (e) => {
        setEmail(e.target.value);

        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value) && e.target.value.length > 0) {
            setInvalidEmail(true);
        }
        else {
            setInvalidEmail(false);
        }
    };

    const handlePhoneInput = (e) => {
        const input = e.target.value.replace(/\D/g, "").slice(0, 10);
        let formattedPhoneNumber = "";
    
        if (input.length > 0) {
            formattedPhoneNumber = `(${input.slice(0, 3)}`;
        }
        if (input.length > 3) {
            formattedPhoneNumber += `) ${input.slice(3, 6)}`;
        }
        if (input.length > 6) {
            formattedPhoneNumber += `-${input.slice(6, 10)}`;
        }

        if (input.length === 0 || input.length === 10) {
            setInvalidPhone(false);
            } else {
            setInvalidPhone(true);
            }
    
        setPhone(formattedPhoneNumber);
    };

    function handleChange(date) {
        setSelectedDate(date);
    }

    function handleClearDate() {
        setSelectedDate(null);
    }


    const handleServicesChange = (selectedOptions) => {
        const updatedServices = selectedOptions.reduce((result, isSelected, index) => {
          result[Object.keys(services)[index]] = isSelected;
          return result;
        }, {});
        setServices(updatedServices);
    }

    const submitNotReady = () => {
        if (invalidEmail === true || invalidPhone === true) {
            return true;
        }

        if (name === "" || guests === "" || description === "" || location === '') {
            return true;
        }

        if (selectedDate === undefined || time === null) {
            return true;
        }

        if (services["Yoga Class"] === false && services["Massage Service"] === false) {
            return true;
        }

        return false;
    }

    async function handleSubmit() {
        try {

            if (submitNotReady()) {
                window.alert('Cannot submit until entire form is complete!');
            }

            else {
                const emailData = {
                email
                };
                const response = await axios.post('/db/add-email', emailData, {
                    headers: {
                      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`
                    }
                  });

                const eventData = {
                    name, emailID: response.data.emailId, phone, guests, date: selectedDate, time, description, services, location
                }

                await axios.post('/db/add-event', eventData, {
                    headers: {
                      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`
                    }
                  });

                window.alert('Your event request has been successfully added!');
                setLocation('');
                setName('');
                setEmail('');
                setPhone('');
                setGuests("");
                setSelectedDate(null);
                setTime("");
                setDescription('');
                setServices({"Yoga Class": false, "Massage Service": false});
                setInvalidEmail(false);
                setInvalidPhone(false);
            }

          } catch (error) {
            console.log(error);
          }
    }


        return (
            <React.Fragment>
                <div className='events-request-container flex-column align-items-center'>
                    <span className='recoleta medium center text-center'>Are you looking to host an amazing event?</span>
                    <br/>
                    <span className='recoleta medium center text-center'>I'll come to you!</span>
                    <br/>
                    <span className='center text-center'>Please provide the following information:</span>
                    <br/>
                    <br/>
                    <div className='flex-column events-inputs-container'>
                        <input className={`events-request-input ${(width < 750) ? 'events-request-input-resize' : ''}`} type="text" placeholder='Full name *' onInput={handleNameInput} value={name} spellCheck={false}/>
                        <input className={`events-request-input ${(width < 750) ? 'events-request-input-resize' : ''} ${(invalidEmail) ? 'events-not-complete' : ''}`} type="text" placeholder='Email *' onInput={handleEmailInput} value={email} spellCheck={false}/>
                        <input className={`events-request-input ${(width < 750) ? 'events-request-input-resize' : ''} ${(invalidPhone) ? 'events-not-complete' : ''}`} type="text" placeholder='Phone number *' onInput={handlePhoneInput} value={phone} spellCheck={false}/>
                        <input className={`events-request-input ${(width < 750) ? 'events-request-input-resize' : ''}`} 
                            type="text" placeholder='How many guests will require my attention? *' 
                            onInput={e => {
                                const inputVal = e.target.value;
                                if (/^[0-9]*$/.test(inputVal)) {
                                    setGuests(inputVal);
                                }
                            }} 
                            value={guests}/>
                        <div className={`flex-row time-container ${(width < 750) ? 'time-container-resize' : ''}`}>
                            <div className='datepicker-container'>
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleChange}
                                    dateFormat="MM/dd/yyyy"
                                    className="form-control events-request-input time-element datepicker-options"
                                    placeholderText={'Event date *'}
                                />
                                { selectedDate === null || selectedDate === undefined  ? <FontAwesomeIcon icon={faCalendar} className='icon-x'/> :<FontAwesomeIcon icon={faCircleXmark} className='icon-x' onClick={handleClearDate}/> }
                            </div>
                            <TimeInput setTime={setTime} time={time}/>
                        </div>
                        <textarea rows='3' className={`events-request-input event-type ${(width < 750) ? 'events-request-input-resize' : ''}`} type="text" placeholder='Which type of event is this? (e.g. Wedding, Community...) *' value={description} onChange={e => setDescription(e.target.value)}/>
                        <span>What services are you looking at: <span style={{color: 'red'}}>*</span></span>
                        <MultiOptionCheckBox options={Object.keys(services)} selectedOptions={Object.values(services)} setSelectedOptions={handleServicesChange}/>
                        <LocationSearchInput setLocation={setLocation} location={location}/>
                        <div className='submit-container'>
                            <div className='submit-button' onClick={handleSubmit}>
                                <span>Submit</span>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

}

export default EventsRequest
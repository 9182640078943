import React from 'react';
import './YogaTab.css';
import yoga from '../../images/yoga.jpeg';
import useWindowDimensions from '../../components/getWindowDimensions';

function YogaHome() {

    const width = useWindowDimensions().width;

    if (width < 1060) {

        return (
            <React.Fragment>

                <div className='couples-container couples-container-resize flex-column'>

                    <div className='flex-column justify-content-center'>
                        <span className='recoleta medium center text-center'>Celebrate your moment ...</span>
                        <br/>
                        <span className='recoleta medium center text-center'>... the moment that matters</span>
                        <br/>
                        <span className='center text-center line-height'>I believe yoga is for everybody and you do not have to be flexible or able to stand on your head.
                            I teach poses that challenge you in a safe way.  Variations of poses are given in each class to make
                            it accessible regardless if you are new to yoga or more experienced.
                        </span>
                        <br/>
                        <span className='center text-center line-height'>Yoga at all levels helps strengthen your body, mind, and spirit. Emphasis on deep breathing tames toxic 
                            stress and pacifies the nervous system. Meditation in movement helps you feel focused, calm and clear.</span>
                    </div>

                    <img src={yoga} className='rounded-borders yoga-home-img-resize' alt='img1'/>

                </div>

            </React.Fragment>
        );

    }

    else {

        return (
            <React.Fragment>

                <div className='couples-container flex-row'>
                    <img src={yoga} className='rounded-borders yoga-home-img-size' alt='img1'/>

                    <div className='flex-column justify-content-center align-items-center'>
                        <span className='recoleta medium center text-center'>Celebrate your moment ...</span>
                        <br/>
                        <span className='recoleta medium center text-center'>... the moment that matters</span>
                        <br/>
                        <span className='center text-center line-height'>I believe yoga is for everybody and you do not have to be flexible or able to stand on your head.
                            I teach poses that challenge you in a safe way.  Variations of poses are given in each class to make
                            it accessible regardless if you are new to yoga or more experienced.
                        </span>
                        <br/>
                        <span className='center text-center line-height'>Yoga at all levels helps strengthen your body, mind, and spirit. Emphasis on deep breathing tames toxic 
                            stress and pacifies the nervous system. Meditation in movement helps you feel focused, calm and clear.</span>
                    </div>

                </div>

            </React.Fragment>
        );

    }
}

export default YogaHome
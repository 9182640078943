import React from 'react';
import { GoogleMap, useLoadScript} from '@react-google-maps/api';
import useWindowDimensions from '../../components/getWindowDimensions';
import './Map.css';

//coordinates to the house
const center = {lat: 36.15230, lng: -80.34552};
const zoom = 16;

const MapStyle = {
  height: '60%',
  width: '40%'
}

const Map_resized = {
  height: '60%',
  width: '80%'
}

const Map_resized_small = {
  height: '415px',
  width: '80%'
}

const GOOGLE_MAPS_LIBRARIES = ['places'];

function MapWithLoadScript({ apiKey }) {

  const width = useWindowDimensions().width;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  if (isLoaded) {
    return (
      <GoogleMap
        mapContainerStyle={(width < 1152) ? (width < 629) ? Map_resized_small : Map_resized : MapStyle}
        center={center}
        zoom={zoom}
      />
    );
  }

  if (loadError) {
    return <span>Error loading map!</span>;
  }

  return <span>Map Loading</span>;
}

function Map({apiKey}) {

  if (apiKey === null) {
    return <span>Loading API key...</span>;
  }

  else {
    return <MapWithLoadScript apiKey={apiKey} />;
  }

}

export default Map;
import './NavBar.css';
import laura_pic from '../../images/laura.jpeg';
import React from 'react';
import DropdownMenuTab from './DropdownMenuTab';
import { Tabs } from '../../App';

function NavBar({setCurrentTab, handleBookingSystemOpenClick, width}) {

  const MassageTherapyMenuItems = {
    "Home": Tabs.MassageTherapy_Home,
    "Traditional Massage": Tabs.MassageTherapy_Trad,
    "Ashiatsu Massage": Tabs.MassageTherapy_Ash,
    "Thai Massage": Tabs.MassageTherapy_Thai,
    "Couples Massage": Tabs.MassageTherapy_Coup
  };

  const YogaMenuItems = {
    "Home": Tabs.Yoga_Home,
    // "Private Yoga": Tabs.Yoga_Private,
    "Group Classes": Tabs.Yoga_Group
  };

  const StudioMenuItems = {
    "Outside and Instructions": Tabs.Studio_Outside,
    "Inside Space": Tabs.Studio_Inside
  };

  if (width > 1350) {
    return(
      <div className='NavBar'>

        <img className='laura-pic' onClick={() => setCurrentTab(Tabs.Home)} src={laura_pic} alt="laura nav bar pic"/>

        <div className='tabs-container'>

          <div className='tab-container' onClick={() => setCurrentTab(Tabs.Home)}>
            <span>Home</span>
          </div>

          <div className='tab-container' onClick={() => setCurrentTab(Tabs.AboutMe)}>
            <span>About Me</span>
          </div>

          <DropdownMenuTab setCurrentTab={setCurrentTab} title={"Massage Therapy"} menuItems={MassageTherapyMenuItems}/>

          <div className='tab-container' onClick={() => setCurrentTab(Tabs.Yogassage)}>
            <span>Yogassage Therapy</span>
          </div>

          <DropdownMenuTab setCurrentTab={setCurrentTab} title={"Yoga Classes"} menuItems={YogaMenuItems}/>

          <div className='tab-container' onClick={() => setCurrentTab(Tabs.Events_Request)}>
            <span>Events</span>
          </div>

          <DropdownMenuTab setCurrentTab={setCurrentTab} title={"The Studio"} menuItems={StudioMenuItems}/>

          <div className='tab-container' onClick={() => setCurrentTab(Tabs.FAQ)}>
            <span>FAQ</span>
          </div>

          <div className='tab-container' onClick={() => {window.open('https://squareup.com/gift/MLTWWBJST3W2A/order')}}>
            <span>Gift Cards</span>
          </div>

        </div>

        <div className='book' onClick={handleBookingSystemOpenClick}>
          <span>Book</span>
        </div>

      </div>
    );
  }
  else {
    return (
      <div className='NavBar resized'>

        <img className='laura-pic resized' onClick={() => setCurrentTab(Tabs.Home)} src={laura_pic} alt="laura nav bar pic"/>

      </div>
    );
  }
}

export default NavBar;
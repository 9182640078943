import React  from "react";
import { useSelector } from 'react-redux';
import moment from 'moment';

function ReasonsText() {

  const { reasons, reasonsExplanation } = useSelector(state => state.bookingSystem);

  const trueReasons = [];

  for (const reason in reasons) {
    if (reasons[reason]) {
      trueReasons.push(reason);
    }
  }

  return (
    <React.Fragment>
      <span className="bold">Reasons:</span>
      <span>{trueReasons.join(", ")}</span>
      {reasonsExplanation && (
        <React.Fragment>
          <span className="bold">Reason Explanation:</span>
          <span>{reasonsExplanation}</span>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}


function MedicalConditionsText() {

  const { medicalConditions } = useSelector(state => state.bookingSystem);

  const trueConditions = [];

  for (const condition in medicalConditions) {
    if (medicalConditions[condition]) {
      trueConditions.push(condition);
    }
  }

  if (trueConditions.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <span className="bold">Medical Conditions:</span>
      <span>{trueConditions.join(", ")}</span>
    </React.Fragment>
  );
}


function AreYouSure() {

    const { selectedService, selectedDate, selectedTime, firstName, lastName, email, phone } = useSelector(state => state.bookingSystem);

    return(
        <React.Fragment>

            <span className='bold medium'>Are you sure?</span>

            <span className="bold">Service:</span>
            <span>{selectedService}</span>

            <span className="bold">Date:</span>
            <span>{moment(selectedDate).format('MMM DD, YYYY')}</span>

            <span className="bold">Time:</span>
            <span>{selectedTime}</span>

            <span className="bold">Client Information:</span>
            <span>{firstName} {lastName}</span>

            <span>{email}</span>

            <span>{phone}</span>

            <ReasonsText/>
            <MedicalConditionsText/>


            

        </React.Fragment>
    );
}

export default AreYouSure;
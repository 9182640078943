import './EventsTab.css'
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';

export default function TimeInput({time, setTime}) {

  function handleChange(event) {

    if(event.target.value === 'Event time *') {
      setTime('');
    }
    else {
      setTime(event.target.value);
    }

  }

  function handleClick() {
    setTime('');
  }

  const times = [];
  times.push(<option value="Event time *"  key={"0"} disabled defaultValue hidden>Event time *</option>);
  let count = 0;
  for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 2; j++) {
          count += 1;
          let d = new Date();
          d.setHours(i);
          d.setMinutes(j * 30);
          let time = d.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
          times.push(<option value={time}  key={`${count}`} >{time}</option>);
      }
  }
  return (
      <div className='select-container'>
          <select 
            value={(time === '') ? "Event time *" : time }  
            onChange={handleChange} 
            className={`events-request-input time-element select-options ${(time === '') ? 'placeholder-color' : 'select-color' }`}>
            {times}
          </select>
          { time === '' ? <FontAwesomeIcon icon={faClock} className='icon-x'/> : <FontAwesomeIcon icon={faCircleXmark} className='icon-x' onClick={handleClick}/> }
      </div>
  );
}
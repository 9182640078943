import React, { useState } from 'react';
import './NavBar.css';

function DropdownMenuTab({setCurrentTab, title, menuItems}) {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
        setIsHovered(false)
    };

  return (
    <div className='dropdown-menu-container'
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
    >

        <div className={`tab-container`}>
            <span>{title}</span>
        </div>

        {isHovered && (<div className='menu'>

        {Object.entries(menuItems).map(([key, value]) => (
            <span className='menu-item' onClick={() => setCurrentTab(value)} key={key}>
              {key}
            </span>
        ))}

        </div>)
        }

    </div>
  );
}

export default DropdownMenuTab;